import classNames from "classnames";

const ActivityFeedItemLoader = () => <div className={"activity-feed-item activity-feed-loader"}>
    <div className={"activity-item-header"}>
        <div className={classNames("avatar-container")}>
            <div
                className={"avatar placeholder"}
                style={{marginRight: "3px",background: "#ccc", width: "40px", height: "40px", borderRadius: "50%"}}
            />
            <div className={"details"}>
                <span className={classNames("label placeholder")}>Xxxxxxx Xxxxxx</span>
                <div className={classNames("under-label placeholder")}>X xxxx xxx</div>
            </div>
        </div>
        <div className={"activity-item-tools"}>
            <div className="tools-indicator placeholder"></div>
        </div>
    </div>
    <div className={"activity-item-content"}>
        <div className={"content-text"}>
            <span className={"placeholder"}>
                Xxxxxx xxxxx xxxxx xxxx xxxx x xxxxxx xxxxx x. Xxx xxx xxxxxxx xxxx x x x xxxxx xxxxxxx xxxxx xxxxxx xxxxxx.
                Xxxxxx xxxxx xxxxx xxxx  xxx xxxxx x. Xxx xxx xxxxxxx xxxx x x x xxxxx xxxxxxx xxxxx xxxxxx xxxxxx. Xxx xx x Xxx.
                Xxxxxx xxxxx xxxxx xxxx xxxx x xxxxxx xxxxx x. Xxx xxx xxxxxxx xxxx x x x xxxxx xxxxxxx xxxxx xxxxxx xxxxxx.
            </span>
        </div>
    </div>
    <div className={"activity-item-footer"}>
        <div className={"placeholder"}>Xxxx</div>
        <div className={"placeholder"}>Xxxx</div>
    </div>
</div>

export default ActivityFeedItemLoader;