import {profileActivityLink} from "../../../../utils/linkUtils";
import {Link} from "react-router-dom";
import React from "react";
import {ProfileInterface} from "../../../../interfaces/ProfileInterface";
import {useTranslation} from "react-i18next";

const ProfileMentioned = (props: {
    notification: {in: {activity?: {uuid: string}}, profile: ProfileInterface},
    signedInProfile: ProfileInterface,
    hideContentsHandler: () => void
}) => {
    const {
        notification,
        signedInProfile,
        hideContentsHandler
    } = props
    const { t } = useTranslation(['navBar']);

    if (notification.in.activity) {
        return <Link
            onClick={hideContentsHandler}
            to={profileActivityLink(
                notification.profile?.slug ?? "",
                notification.in.activity.uuid
            )}
        >
            {t(
                "navBar:notifications.profileMentioned",
            )}
        </Link>
    }

    return <></>
}

export default ProfileMentioned;