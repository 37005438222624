import * as constants from './../constants';
import {AuthInterface} from "../../interfaces/AuthInterface";
import {ProfileInterface} from "../../interfaces/ProfileInterface";

const INITIAL_STATE: AuthInterface = {
    token: localStorage.getItem('token') ?? null,
    tokenType: localStorage.getItem('tokenType') ?? null,
    isSignedIn: localStorage.getItem('token') !== null && localStorage.getItem('tokenType') !== null,
    isVerified: false,
    profiles: [],
    currentProfile: null,
};

interface ActionInterface {
    type: string;
    payload: any;
}

export default function authReducer(state = INITIAL_STATE, action: ActionInterface) {
    switch(action.type) {
        case constants.SET_AUTH_TOKEN:
            localStorage.setItem('token', action.payload.token);
            localStorage.setItem('tokenType', action.payload.tokenType);

            return {
                ...state,
                ...action.payload,
                isSignedIn: true
            };
        case constants.CLEAR_AUTH:
            localStorage.removeItem('token');
            localStorage.removeItem('tokenType');

            return {
                token: null,
                tokenType: null,
                isSignedIn: false,
                isVerified: false,
                profiles: [],
                currentProfile: null
            };
        case constants.FETCH_USER_PROFILES:
            return {
                ...state,
                profiles: action.payload,
                currentProfile: getCurrentProfile(action.payload),
                isVerified: true
            };
        case constants.SWITCH_PROFILE:
            const profile = state.profiles.find((profile) => profile.uuid === action.payload.uuid);
            localStorage.setItem('lastProfileUuid', profile?.uuid ?? "");
            return {
                ...state,
                currentProfile: profile
            };
        default:
            return state;
    }

    function getCurrentProfile(payload: any) {
        if (payload.length === 1) {
            localStorage.setItem('lastProfileUuid', payload[0].uuid);
            return payload[0];
        }

        const lastProfileUuid = localStorage.getItem('lastProfileUuid');
        if (lastProfileUuid) {
            let lastProfile = payload.find((profile: ProfileInterface) => profile.uuid === lastProfileUuid);
            if (lastProfile) {
                return lastProfile;
            }
        }

        const personalProfile = payload.find((profile: ProfileInterface) => profile.type === 'personal');
        if (personalProfile) {
            localStorage.setItem('lastProfileUuid', personalProfile.uuid);
            return personalProfile;
        }

        localStorage.removeItem('lastProfileUuid');

        return null;
    }
}