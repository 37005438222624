import * as constants from "../constants";

export const giveReactionForActivity = (uuid: string, data: {reaction: string}, onSuccess: any, onError: any) => ({
    type: constants.API,
    payload: {
        method: 'PUT',
        url: '/activities/'+ uuid + '/reactions/',
        data: data,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const withdrawnActivityReaction = (uuid: string, data: {reaction: string}, onSuccess: any, onError: any) => ({
    type: constants.API,
    payload: {
        method: 'DELETE',
        url: '/activities/'+ uuid + '/reactions/',
        data: data,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});