import ContentText from "./Content/ContentText";
import {ActivityContentInterface} from "../../../../interfaces/ActivityInterface";
import ContentChallenge from "./Content/ContentChallenge";

const ActivityContent = (props: {
    content: ActivityContentInterface,
}) => {
    const {content} = props;

    return <div className={"activity-item-content"}>
        {content.text && <ContentText content={content.text}/>}
        {content.challenge && <ContentChallenge challenge={content.challenge} />}
    </div>
}

export default ActivityContent;