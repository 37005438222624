import {connect} from "react-redux";
import {ProfileInterface} from "../../interfaces/ProfileInterface";
import {useEffect, useRef} from "react";
import {Helmet} from "react-helmet-async";
import ActivityFeed from "../../components/Activity/ActivityFeed";
import {useTranslation} from "react-i18next";

const WallPage = (props: {signedInProfile: ProfileInterface}) => {
    const {signedInProfile} = props;
    const signedInProfileRef = useRef<ProfileInterface | null>();

    const { t } = useTranslation(['page']);

    useEffect(() => {
        if (signedInProfileRef.current && signedInProfileRef.current?.uuid === signedInProfile.uuid) {
            return;
        }

        signedInProfileRef.current = signedInProfile;
    }, [signedInProfile]);

    return <>
        <Helmet>
            <title>{t("page:wall.title")}</title>
            <meta name="robots" content="index,follow" />
            <meta name="description" content={t("page:wall.description")} />
            <meta name="keywords" content={t("page:wall.keywords")}/>
        </Helmet>
        <main>
            <ActivityFeed key={signedInProfile.uuid} signedInProfile={signedInProfile} />
        </main>
    </>
}

const mapStateToProps = (state: any) => ({
    signedInProfile: state.auth.currentProfile
});

export default connect(mapStateToProps, null)(WallPage);