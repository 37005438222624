import React, {useEffect, useState} from "react";
import {fetchFilteredDisciplines} from "../../../redux/actions/disciplineActions";
import {connect} from "react-redux";
import classNames from "classnames";
import ContentChallenge from "../Feed/Item/Content/ContentChallenge";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendar, faMedal, faStopwatch} from "@fortawesome/free-solid-svg-icons";
import moment from "moment/moment";
import {useTranslation} from "react-i18next";
import {fetchPlacesBySport} from "../../../redux/actions/placeActions";

interface DisciplineVariant {
    teams: null|number, teamMembers: null|number
}

interface Discipline {
    id: number,
    tag: string,
    name: string,
    variants: DisciplineVariant[]
}

interface Place {
    uuid: string,
    name: string,
}

const Challenge = (props: {
    dispatchFetchFilteredDisciplinesAction: (phrase: string | null, onSuccess: any, onError: any) => void
    dispatchFetchPlacesBySport: (sportId: number, onSuccess: any, onError: any) => void
}) => {
    const {
        dispatchFetchFilteredDisciplinesAction,
        dispatchFetchPlacesBySport
    } = props;

    const [step, setStep] = useState(1);
    const [disciplines, setDisciplines] = useState<Discipline[]>([]);
    const [variants, setVariants] = useState<DisciplineVariant[]>([]);
    const [places, setPlaces] = useState<Place[]>([]);
    const [challenge, setChallenge] = useState<{place?: null|any, time?: any,discipline?: {id: number, name: string, tag: string, variant?: {teams: null|number, teamMembers: null|number}}}>({});

    const { t, i18n } = useTranslation(['challenge', 'discipline', 'time']);

    useEffect(() => {
        dispatchFetchFilteredDisciplinesAction(
            null,
            (response: {items: Discipline[]}) => {setDisciplines(response.items)},
            (error: any) => console.log(error)
        )
    }, []);

    const onDisciplineSelected = (discipline: Discipline) => {
        setChallenge(prevState => ({
            ...prevState,
            discipline: {
                ...prevState.discipline,
                id: discipline.id,
                name: discipline.name,
                tag: discipline.tag,
                variant: undefined
            },
        }));
        setStep(2);
        setVariants(discipline.variants);

        dispatchFetchPlacesBySport(discipline.id, (response: {items: any}) => setPlaces(response.items), (error: any) => console.log(error));
    }

    const onDisciplineVariantSelected = (variant: DisciplineVariant) => {
        setChallenge((prevState) => {
            if (prevState.discipline) {
                return {
                    ...prevState,
                    discipline: {
                        ...prevState.discipline,
                        variant: variant,
                    },
                };
            }
            return prevState;
        });
        setStep(3);
    }

    const onTimeSelected = (time: any) => {
        setChallenge(prevState => ({
            ...prevState,
            time: time,
        }));
        setStep(4);
    }

    const onPlaceSelected = (place: null|Place) => {
        setChallenge(prevState => ({
            ...prevState,
            place: place,
        }));
        setStep(5);
    }

    console.log(challenge);
    return <div className={"publish-form-content-challenge"}>
        <div className={"steps"}>
            <ul className={"horizontal-progress"}>
                <li className={classNames({"done": step >= 1})}><span className={"label"}>Discipline</span></li>
                <li className={classNames({"done": step >= 2})}><span className={"label"}>Participants</span></li>
                <li className={classNames({"done": step >= 3})}><span className={"label"}>Time</span></li>
                <li className={classNames({"done": step >= 4})}><span className={"label"}>Place</span></li>
                <li className={classNames({"done": step >= 5})}><span className={"label"}>Summary</span></li>
            </ul>
        </div>
        {step === 1 && <div className={"discipline-selection"}>
            <h4>Select discipline</h4>
            <input placeholder="find discipline" className={"find-discipline-input"} />
            <div className={"disciplines-list"}>
                {disciplines.map((discipline) => <div
                        key={discipline.id}
                        className={classNames("discipline", discipline.tag)}
                    >
                        <input  id={discipline.tag} type={"radio"} name={"challenge[discipline]"} />
                        <label htmlFor={discipline.tag} onClick={() => onDisciplineSelected(discipline)}>{discipline.name}</label>
                    </div>
                )}
            </div>
        </div>}
        {step === 2 && <div className={"discipline-variant-selection"}>
            <h4>Select participants variant</h4>
            {variants.map((variant, index) => <div
                style={{cursor: "pointer"}}
                onClick={() => onDisciplineVariantSelected(variant)}
                key={index}
                className={"variant"}
            >
                {variant.teams === 1 && <div>{variant.teamMembers === null ? "∞" : variant.teamMembers}</div>}
                {variant.teams === 2 && <div>{variant.teamMembers === null ? "∞" : variant.teamMembers} vs {variant.teamMembers === null ? "∞" : variant.teamMembers}</div>}
                {variant.teams !== null && variant.teams > 2 && <div>{variant.teams} x {variant.teamMembers === null ? "∞" : variant.teamMembers}</div>}
                {variant.teams === null && <div>∞ x {variant.teamMembers === null ? "∞" : variant.teamMembers}</div>}
            </div>)}
        </div>}
        {step === 3 && <div className={"time-selection"}>
            <h4>Select time</h4>
            <div onClick={() => onTimeSelected({type: "frame", start:"2023-12-31T23:30:00", ends:"2024-01-01T00:30:00"})}>60min</div>
        </div>}
        {step === 4 && <div className={"place-selection"}>
            <h4>Select place</h4>
            {places.map((place: Place) => <div key={place.uuid} onClick={() => onPlaceSelected(place)}>{place.name}</div>)}
            <div onClick={() => onPlaceSelected(null)}>Anywhere</div>
        </div>}
        {step === 5 && <div className={"preview"}>
            <div className={"content-challenge"}>
                <div className={classNames("challenge-header", challenge.discipline?.tag)}>
                    <div className={"challenge-name"}>
                        <h3>
                            Challenge title
                        </h3>
                    </div>
                    <div className={"challenge-details"}>
                        <div className={"challenge-details-row"}>
                            <span>{t("discipline:" + challenge.discipline?.tag)}</span>
                            <span><FontAwesomeIcon icon={ faCalendar } /> { moment(challenge.time.start).format('LLL') }</span>
                            <span><FontAwesomeIcon icon={ faStopwatch} /> xxx</span>
                            <span>
                        <FontAwesomeIcon icon={ faMedal } />
                                { ['beginner', 'intermediate', 'advanced'].map((level) => t("challenge:level." + level)).join(", ") }
                    </span>
                        </div>
                        <div className={"challenge-details-row"}>xxx</div>
                    </div>
                </div>
            </div>
        </div>}
    </div>
}

const mapDispatchToProps = (dispatch: any) => ({
    dispatchFetchFilteredDisciplinesAction: (phrase: string | null, onSuccess: any) => dispatch(
        fetchFilteredDisciplines(
            phrase
                ? {search: {phrase: phrase}, perPage: 50, sort: {name: 'asc'}}
                : {perPage: 50, sort: {name: 'asc'}},
            onSuccess,
            (error: any) => console.log(error)
        )
    ),
    dispatchFetchPlacesBySport: (sportId: number, onSuccess: any, onError: any) => dispatch(
        fetchPlacesBySport({sportId: sportId}, onSuccess, onError)
    ),
});

export default connect(null, mapDispatchToProps)(Challenge);