import {Link} from "react-router-dom";

const ContentText = (props: {content: string}) => {
    const {content} = props;

    const replaceMentions = (text: string): any[] => {
        let preparedText: any[] = [];
        let x = [...text.matchAll(/\@{{([a-z]+)\|\|([0-9-a-z]+)\|\|([a-z À-ž]+)\}\}/gi)]

        text.split(/\@{{[a-z]+\|\|[0-9-a-z]+\|\|[a-z À-ž]+\}\}/gi).forEach((el, i) => {
            preparedText.push(el);
            if (x[i]) {
                preparedText.push(<Link key={'span-' + i} className={'link-to-' + x[i][1]} to={'/' + x[i][2] + '/'}>{x[i][3]}</Link>)
            }
        })

        // x.forEach((el) => {
        //     // preparedText = preparedText.replace(el[0], `<a href="/` + el[2] + `/">` + el[3] + `</a>`)
        // })

        return preparedText;
    }

    return <div className={"content-text"}>{replaceMentions(content)}</div>
}

export default ContentText;