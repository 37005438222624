import {ChallengeInterface} from "../../../../../interfaces/ChallengeInterface";
import classNames from "classnames";
import {Link} from "react-router-dom";
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendar, faMedal, faStopwatch} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import {nameInstrumental} from "../../../../../utils/profile";

const ContentChallenge = (props: {challenge: ChallengeInterface}) => {
    const {
        uuid,
        name,
        date,
        discipline,
        levels,
        duration,
        profile
    } = props.challenge

    const { t, i18n } = useTranslation(['challenge', 'discipline', 'time']);

    const prepareTitle = () => {
        if (name) {
            return name;
        }

        const timeOfDay = () => {
            const hour = moment(date).hour();
            if (hour > 5 && hour < 12) return 'morning';
            if (hour >= 12 && hour < 18) return "afternoon";
            if (hour >= 18 && hour < 23) return "evening";

            return 'night';
        }

        const title = t(
            "challenge:title.long",
            {
                name: nameInstrumental(profile, i18n.language),
                onDay: t("time:onDay." + moment(date).isoWeekday()),
                dayTime: t("time:timeOfDay." + timeOfDay()),
                discipline: t("discipline:" + discipline),
            });

        return title.charAt(0).toUpperCase() + title.slice(1)
    }

    const calculateDuration = (minutes: number) => {
        let duration = [];
        const durationDays = Math.floor(minutes / (60 * 24));
        if (durationDays) duration.push(durationDays + 'd');

        const durationHours = Math.floor((minutes - durationDays * 60 * 24) / 60);
        if (durationHours) duration.push(durationHours + 'H');

        const durationMinutes = Math.floor(minutes % 60);
        if (durationMinutes) duration.push(durationMinutes + 'min');

        return duration.join(' ')
    }

    return <div className={"content-challenge"}>
        <div className={classNames("challenge-header", discipline)}>
            <div className={"challenge-name"}>
                <h3>
                    <Link to={"/challenges/" + uuid + "/"}>{prepareTitle()}</Link>
                </h3>
            </div>
            <div className={"challenge-details"}>
                <div className={"challenge-details-row"}>
                    <span>{t("discipline:" + discipline)}</span>
                    <span><FontAwesomeIcon icon={ faCalendar } /> { moment(date).format('LLL') }</span>
                    <span><FontAwesomeIcon icon={ faStopwatch} /> {calculateDuration(duration)}</span>
                    <span>
                        <FontAwesomeIcon icon={ faMedal } />
                        { levels.map((level) => t("challenge:level." + level)).join(", ") }
                    </span>
                </div>
                <div className={"challenge-details-row"}>{uuid}</div>
            </div>
        </div>
    </div>
}

export default ContentChallenge;