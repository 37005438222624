import i18n from "i18next";
import moment from "moment";
import React, {useState} from "react";
import "./LanguageSwitch.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFlag} from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import {useOutsideClick} from "../../hooks/useOutsideClick";

const LanguageSwitch = () => {
    const [isOpen, setIsOpen] = useState(false);

    const languageSwitchRef = useOutsideClick(() => setIsOpen(false));

    const languages = [
        {code: "de", label: "deutsch"},
        {code: "en", label: "english"},
        {code: "es", label: "español"},
        {code: "pl", label: "polski"},
        {code: "sv", label: "svenska"}
    ];

    const onIndicatorClicked = (e: any): void => {
        e.preventDefault();
        setIsOpen(!isOpen);
    }

    const onChangeLanguageClicked = (e: any): void => {
        e.preventDefault();
        const languageCode = e.target.getAttribute("data-language");
        setIsOpen(false);
        i18n.changeLanguage(languageCode).then((): void => {
            moment.locale(i18n.language);
            document.documentElement.lang = i18n.language;
        });
    }

    return <div ref={languageSwitchRef} className={classNames("language-switch", "shadow", {open: isOpen})}>
        <div className={"indicator"} onClick={(e) => onIndicatorClicked(e)}>
            <FontAwesomeIcon icon={faFlag} />
        </div>
        <div className={"languages"}>
            {languages.map((language) => <button
                className={classNames({selected: i18n.language === language.code})}
                key={language.code}
                data-language={language.code}
                onClick={(e) => {onChangeLanguageClicked(e)}}
            >
                {language.label}
            </button>)}
        </div>
    </div>
}

export default LanguageSwitch;