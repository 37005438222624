import {useEffect, useRef, useState} from "react";
import ActivityFeedItemLoader from "../../components/Activity/Feed/ActivityFeedItemLoader";
import {fetchSingleActivity} from "../../redux/actions/activityActions";
import {ActivityInterface} from "../../interfaces/ActivityInterface";
import {useParams} from "react-router-dom";
import {connect} from "react-redux";
import ActivityFeedItem from "../../components/Activity/Feed/ActivityFeedItem";
import {ProfileInterface} from "../../interfaces/ProfileInterface";
import {Helmet} from "react-helmet-async";

const SingleActivityPage = (props: {dispatchFetchSingleActivityAction: (
        uuid: string,
        onSuccess: any,
        onError: any,
    ) => {},
    signedInProfile: ProfileInterface
}) => {
    const params = useParams<{ slug: string, uuid: string }>();
    const slug = params.slug?.match(/[a-z0-9-]+/i)?.input;
    const uuid = params.uuid?.match(/[a-z0-9-]+/i)?.input;

    const {
        dispatchFetchSingleActivityAction,
        signedInProfile
    } = props

    const [isLoading, setIsLoading] = useState(true);
    const [activity, setActivity] = useState<ActivityInterface>();

    const uuidRef = useRef<string>();

    useEffect(() => {
        if (uuid === undefined || uuid === uuidRef.current) {
            return;
        }

        uuidRef.current = uuid;
        setIsLoading(true);

        dispatchFetchSingleActivityAction(
            uuid,
            (response: any) => {
                setActivity(response);
                setIsLoading(false);
            },
            (error: any) => console.log(error)
        )
    }, [uuid]);


    const handleActivityDeleted = (deletedActivityUuid: string) => {
        setActivity(undefined);
    }

    if (isLoading) {
        return <main><div className={"activity-feed shadow"}><ActivityFeedItemLoader /></div></main>
    }

    return <>
        <Helmet>

        </Helmet>
        <main>
            <div className={"activity-feed shadow"}>
                {activity && <ActivityFeedItem
                    activityDeletedHandler={(uuid: string) => handleActivityDeleted(uuid)}
                    activity={activity}
                    signedInProfile={signedInProfile}
                />}
            </div>
        </main>
        </>
}

const mapDispatchToProps = (dispatch: any) => ({
    "dispatchFetchSingleActivityAction": (
        uuid: string,
        onSuccess: (response: any) => {},
        onError: (error: any) => {}
    ) => dispatch(fetchSingleActivity(uuid, onSuccess, onError)),
});

const mapStateToProps = (state: any) => ({
    signedInProfile: state.auth.currentProfile
});

export default connect(mapStateToProps, mapDispatchToProps)(SingleActivityPage);