import {ProfileInterface} from "../../interfaces/ProfileInterface";
import {ActivityInterface} from "../../interfaces/ActivityInterface";
import ActivityFeedItem from "./Feed/ActivityFeedItem";
import {useEffect, useState} from "react";
import {InView} from "react-intersection-observer";
import ActivityFeedItemLoader from "./Feed/ActivityFeedItemLoader";
import ActivityPublishForm from "./ActivityPublishForm";
import {fetchActivities, fetchProfileActivities} from "../../redux/actions/activityActions";

import "./ActivityFeed.scss";
import {connect} from "react-redux";
import {getQueryParams} from "../../utils/URLUtils";
import NoMoreContent from "./Feed/NoMoreContent";
import NoContent from "./Feed/NoContent";
import NoProfileContent from "./Feed/NoProfileContent";

interface GetFeedParams {
    before: string;
}

const ActivityFeed = (props: {
    profile?: ProfileInterface,
    signedInProfile: ProfileInterface,
    dispatchFetchActivitiesAction: (params: GetFeedParams, onSuccess: any, onError: any) => {},
    dispatchFetchProfileActivitiesAction: (uuid: string, params: GetFeedParams, onSuccess: any, onError: any ) => {}
}) => {
    const {
        profile,
        signedInProfile,
        dispatchFetchActivitiesAction,
        dispatchFetchProfileActivitiesAction,
    } = props;

    useEffect(() => {

    }, [signedInProfile]);

    const [feedIsLoading, setFeedIsLoading] = useState(false);
    const [feed, setFeed] = useState<ActivityInterface[]>([]);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [nextPageParams, setNextPageParams] = useState<any>({})

    const handleActivityDeleted = (deletedActivityUuid: string) => {
        const updatedFeed = feed.filter(
            (activity) => activity.uuid !== deletedActivityUuid
        );
        setFeed(updatedFeed);
    }

    const handleActivityPublished = (activity: ActivityInterface) => {
        setFeed([activity].concat(feed))
    }
    const handleActivityLoaderVisibilityChanged = (inView: boolean) => {
        if (!inView || feedIsLoading) {
            return
        }

        handleFetchActivities()
    }

    const handleFetchActivities = () => {
        setFeedIsLoading(true);
        const handleSuccessResponse = (response: {items: Array<ActivityInterface>, meta: {links: {next?: string}}}) => {
            if (response.meta.links.next) {
                setNextPageParams(getQueryParams(response.meta.links.next));
            } else {
                setHasMore(false)
            }

            setFeed(feed.concat(response.items))
            setFeedIsLoading(false)
        }

        const handleErrorResponse = (error: any) => {
            console.log(error)
        }

        if (profile) {
            dispatchFetchProfileActivitiesAction(profile.uuid, nextPageParams, handleSuccessResponse, handleErrorResponse)
        } else {
            dispatchFetchActivitiesAction(nextPageParams, handleSuccessResponse, handleErrorResponse);
        }
    }

    return <>
    {signedInProfile && <div className={"activity-feed shadow"} style={{overflow: "unset"}}>
            <ActivityPublishForm profile={profile} signedInProfile={signedInProfile} activityPublishedHandler={handleActivityPublished} />
        </div>
    }
        <div className={"activity-feed shadow"}>
            {feed.map((activity: ActivityInterface) => <ActivityFeedItem
                signedInProfile={signedInProfile}
                key={activity.uuid} activity={activity}
                activityDeletedHandler={(uuid: string) => handleActivityDeleted(uuid)}
            />)}
            {hasMore && <InView onChange={handleActivityLoaderVisibilityChanged}>
                <ActivityFeedItemLoader />
                <ActivityFeedItemLoader />
            </InView>}
            {feed.length === 0 && !hasMore && <>{profile ? <NoProfileContent profile={profile} /> : <NoContent signedInProfile={signedInProfile} />}</>}
        </div>

        {feed.length > 0 && !hasMore && <div className={"activity-feed shadow"}>
            <NoMoreContent profile={profile} />
        </div>}
    </>
}

const mapDispatchToProps = (dispatch: any) => ({
    "dispatchFetchActivitiesAction": (params: any, onSuccess: any, onError: any) => dispatch(fetchActivities(params, onSuccess, onError)),
    "dispatchFetchProfileActivitiesAction": (uuid: string, params: any, onSuccess: any, onError: any) => dispatch(fetchProfileActivities(uuid, params, onSuccess, onError)),
});

export default connect(null, mapDispatchToProps)(ActivityFeed);