import Moment from "react-moment";
import moment from "moment";

import {LabeledAvatar} from "../../../_ui/Avatar";
import {ProfileInterface} from "../../../../interfaces/ProfileInterface";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLock, faLockOpen} from "@fortawesome/free-solid-svg-icons";
import ActivityTools from "./Header/ActivityTools";
import {profileActivityLink, profileLink} from "../../../../utils/linkUtils";
import {useState} from "react";

const ActivityHeader = (props: {
    uuid?: string;
    occurredAt: string;
    author: ProfileInterface;
    target?: {
        profile?: ProfileInterface
    };
    visibility?: string;
    signedInProfile?: ProfileInterface;
    beforeActivityDeletedHandler: () => void;
    activityDeletedHandler: (uuid: string) => void;
    setToEditModeHandler: () => void;
    hideTools?: boolean
}) => {
    const {
        uuid,
        author,
        occurredAt,
        visibility,
        target,
        signedInProfile,
        beforeActivityDeletedHandler,
        activityDeletedHandler,
        setToEditModeHandler,
        hideTools,
    } = props

    const { t, i18n } = useTranslation(['activity']);
    moment.locale(i18n.language);

    return <>
            <div className={"activity-item-header"}>
            <LabeledAvatar
                src={author.avatar?.url}
                alt={author.name}
                linkTo={profileLink(author.slug)}
            >
                {target?.profile && <span>
                    <LabeledAvatar
                        src={target.profile.avatar?.url}
                        alt={target.profile.name}
                        labelStyle={{fontSize: "13.33333px"}}
                        size={"size-s"}
                        imageStyle={{width: "15px", height: "15px"}}
                        linkTo={profileLink(target.profile.slug)}
                    />
                </span>}
                {uuid
                    ? <Link
                        to={profileActivityLink(author.slug, uuid)}
                        className={"publication-time"}
                        title={moment(occurredAt).format('LLLL')}
                    >
                        <Moment fromNow={ true } interval={30000} date={ occurredAt } />
                    </Link>
                    : <Moment className={"publication-time"} fromNow={ true } interval={30000} date={ occurredAt } />
                }
                {visibility && <span
                    title={t("activity:feed.item.visibility." + visibility)}
                    className={"visibility"}
                >
                    <FontAwesomeIcon icon={visibility === "public" ? faLockOpen : faLock} />
                </span>}
            </LabeledAvatar>
            {!hideTools && uuid && <ActivityTools
                uuid={uuid}
                author={author}
                signedInProfile={signedInProfile}
                beforeActivityDeletedHandler={beforeActivityDeletedHandler}
                activityDeletedHandler={activityDeletedHandler}
                setToEditModeHandler={setToEditModeHandler}
            />}
        </div>
    </>
}

export default ActivityHeader;