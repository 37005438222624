import classNames from "classnames";

import './Layout.scss';

const Columns = ({className, style, children}) => {
    return <div className={classNames('columns-layout', className)} style={style}>
        {children}
    </div>
}

export default Columns;