import * as constants from './../constants';

export const fetchActivities = (params, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: '/activities/',
        params: params,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const deleteActivity = (uuid, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'DELETE',
        url: '/activities/'+ uuid + '/',
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const reportActivity = (uuid, data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'POST',
        data: data,
        url: '/activities/'+ uuid + '/reports/',
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const fetchProfileActivities = (uuid, params, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: '/profiles/' + uuid + '/activities/',
        params: params,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const fetchSingleActivity = (uuid, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: '/activities/' + uuid + '/',
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const publishActivity = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'POST',
        url: '/activities/',
        data: data,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const publishInProfileActivity = (profileUuid, data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'POST',
        url: '/profiles/' + profileUuid + '/activities/',
        data: data,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});