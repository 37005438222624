import classNames from "classnames";
import {Link} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {AuthInterface} from "../../interfaces/AuthInterface";
import {signOutCurrent} from "../../redux/actions/authActions";
import {connect} from "react-redux";

import "./NavBar.scss";
import pusher from "../../utils/pusher";
import {LabeledAvatar} from "../_ui/Avatar";
import {profileLink} from "../../utils/linkUtils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBell, faEnvelope} from "@fortawesome/free-solid-svg-icons";
import {useOutsideClick} from "../../hooks/useOutsideClick";
import {Channel} from "pusher-js";
import Messages from "./NavContents/Messages";
import Notifications from "./NavContents/Notifications";
import Menu from "./NavContents/Menu";
import {fetchNotifications} from "../../redux/actions/notificationActions";

const NavBar = (props: {auth: AuthInterface, dispatchFetchNotificationsAction: any}) => {
    const {
        auth,
        dispatchFetchNotificationsAction,
    } = props

    const [notifications, setNotifications] = useState<{uuid: string, type: string, time: string, data: any, status: string}[]>([]);
    const [pusherChannel, setPusherChannel] = useState<Channel|null>(null);

    const [activeNavContents, setActiveNavContents] = useState<"menu"|"notifications"|"messages"|null>(null);
    const userNavRef = useOutsideClick(() => setActiveNavContents(null));

    useEffect(() => {
        if (auth.currentProfile === null) {
            return;
        }

        dispatchFetchNotificationsAction(
            (response: any) => setNotifications(response.items),
            (error: any) => console.log(error),
            'new'
        )
    }, [auth.currentProfile]);

    useEffect(() => {
        const channelName = "profiles." + auth.currentProfile?.uuid + ".notifications";
        const channel = pusher.subscribe(channelName);
        setPusherChannel(channel);
        setNotifications([]);
        return (() => {
            pusher.unsubscribe(channelName)
        })
    }, [auth.currentProfile]);

    useEffect(() => {
        if(pusherChannel && pusherChannel.bind){
            pusherChannel.unbind("NotificationOccurred");
            pusherChannel.bind("NotificationOccurred",(notification: {uuid: string, type: string, time: string, data: any, status: string}) => {
                setNotifications([notification].concat(notifications));
            })
        }
    }, [pusherChannel, notifications]);

    const onToggleNavClicked = (e: any, activeElement: "menu"|"notifications"|"messages") => {
        e.preventDefault();
        setActiveNavContents(activeNavContents !== null && activeNavContents === activeElement ? null : activeElement);
    }

    const newNotifications = notifications.filter((notification: {status: string}) => notification.status === "new").length;

    return <div className={classNames("navbar", "shadow")}>
        <div className={"wrapper"}>
            <div className={"brand"}>
                <Link to={"/"}>
                    <img
                        src="/img/sportzzy_logo.svg"
                        alt="sportzzy" title="Sportzzy - find a sport partner and stay active."
                        style={{height: '40px', display: 'block'}}
                    />
                </Link>
            </div>
            {auth.isSignedIn && auth.currentProfile && <div ref={userNavRef} className={classNames("user-nav", activeNavContents, {open: activeNavContents !== null})}>
                <div className={"nav-items"}>
                    <LabeledAvatar
                        className={"signed-in-profile"}
                        size={"size-s"}
                        alt={auth.currentProfile.name}
                        src={auth.currentProfile.avatar?.url}
                        linkTo={profileLink(auth.currentProfile.slug)}
                        onClick={() => setActiveNavContents(null)}
                    />

                    <div className={"profile-notifications"}>
                        <div className={"ico"} onClick={(e:any) => onToggleNavClicked(e, "notifications")}>
                            <FontAwesomeIcon icon={faBell} shake={newNotifications > 0}/>
                            {newNotifications > 0 && <div className={"badge"}>{newNotifications}</div>}
                        </div>
                        <div className={"ico"} onClick={(e:any) => onToggleNavClicked(e, "messages")}>
                            <FontAwesomeIcon icon={faEnvelope} />
                        </div>
                    </div>

                    <div className={"nav-indicator"} onClick={(e) => onToggleNavClicked(e, "menu")}>
                        ---
                    </div>
                </div>
                <div className={"nav-contents shadow"} style={{minWidth: userNavRef.current?.clientWidth ? (userNavRef.current?.clientWidth - 10) : undefined}}>
                    {activeNavContents === "menu" && <Menu auth={auth} hideContentsHandler={() => setActiveNavContents(null)} /> }
                    {activeNavContents === "notifications" && <Notifications
                        notifications={notifications}
                        signedInProfile={auth.currentProfile}
                        setNotifications={(notifications) => setNotifications(notifications)}
                        hideContentsHandler={() => setActiveNavContents(null)}
                    />}
                    {activeNavContents === "messages" && <Messages messages={[]} />}
                </div>
            </div>}
        </div>
    </div>
}
const mapDispatchToProps = (dispatch: any) => ({
    "dispatchFetchNotificationsAction":  (onSuccess: any, onError: any, status?: string|null) => dispatch(fetchNotifications(onSuccess, onError, status)),
    "dispatchSignOutAction":  (onSuccess: any, onError: any) => dispatch(signOutCurrent(onSuccess, onError))
});

const mapStateToProps = (state: any) => ({
    auth: state.auth
});

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);