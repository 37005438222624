import {connect} from "react-redux";
import {ProfileInterface} from "../../interfaces/ProfileInterface";
import {useEffect, useRef, useState} from "react";
import {Helmet} from "react-helmet-async";
import ActivityFeed from "../../components/Activity/ActivityFeed";
import {useParams} from "react-router-dom";
import {fetchProfileBySlug} from "../../redux/actions/profileActions";
import {Avatar} from "../../components/_ui/Avatar";

import "./ProfilePage.scss";
import classNames from "classnames";
import ActivityFeedItemLoader from "../../components/Activity/Feed/ActivityFeedItemLoader";
import {useTranslation} from "react-i18next";

const ProfilePage = (props: {signedInProfile: ProfileInterface, dispatchFetchProfileBySlug: any}) => {
    const params = useParams<{ slug?: string }>();
    const slug = params.slug?.match(/[a-z0-9-]+/i)?.input;
    const slugRef = useRef<string>();

    const {
        signedInProfile,
        dispatchFetchProfileBySlug
    } = props;
    const [isLoading, setIsLoading] = useState(true);
    const [profile, setProfile] = useState<ProfileInterface | null>(null)

    const { t } = useTranslation(['page']);

    useEffect(() => {
        if (slugRef.current === slug) {
            return;
        }

        setIsLoading(true);
        slugRef.current = slug;

        dispatchFetchProfileBySlug(
            slug,
            (response: any) => {
                setProfile(response);
                setIsLoading(false);
            },
            (error: any) => {
                console.log(error);
                setIsLoading(false);
            }
        )
    }, [slug, signedInProfile]);

    if (isLoading) {
        return <main>
            <div className={"profile-header shadow placeholder"}>
                <div className={classNames("avatar-container")}>
                    <div
                        className={"avatar placeholder"}
                        style={{marginRight: "3px",background: "#ccc", width: "102px", height: "102px", borderRadius: "50%"}}
                    />
                </div>
                <h2 className={"placeholder"}>Xxxxxx Xxxxxxxxx</h2>
            </div>

            <div className="activity-feed shadow">
                <div className={"placeholder"} style={{padding: "15px", background: "#fff"}}>
                    <div className={classNames("avatar-container")}>
                        <div
                            className={"avatar placeholder"}
                            style={{marginRight: "3px",background: "#ccc", width: "40px", height: "40px", borderRadius: "50%"}}
                        />
                    </div>
                </div>
            </div>
            <div className={"activity-feed shadow"}><ActivityFeedItemLoader /><ActivityFeedItemLoader /></div>
        </main>
    }

    return <>
        {profile && <Helmet>
            <title>{t("page:profile.title", {name: profile.name, city: profile.city})}</title>
            <meta name="robots" content="index,follow" />
            <meta name="description" content={t("page:profile.description")} />
            <meta name="keywords" content={t("page:profile.keywords")} />
        </Helmet>}
        <main>
            {profile && <div className={"profile-header shadow"}>
                <Avatar size={"size-xl"} src={profile.avatar?.url} alt={profile.name} />
                <h2>{profile.name}</h2>
            </div>}
            {profile && signedInProfile && <ActivityFeed key={profile.uuid} signedInProfile={signedInProfile} profile={profile} />}
        </main>
    </>
}

const mapStateToProps = (state: any) => ({
    signedInProfile: state.auth.currentProfile
});

const mapDispatchToProps = (dispatch: any) => ({
    "dispatchFetchProfileBySlug": (
        slug: string,
        onSuccess: (response: any) => {},
        onError: (error: any) => {}
    ) => dispatch(fetchProfileBySlug(slug, onSuccess, onError)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);