import * as constants from './../constants';

export const fetchNotifications = (
    onSuccess: (response: any) => {},
    onError: (error: any) => {},
    status?: string|null
) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: '/notifications/',
        params: {search: {status: status ?? null}},
        postProcessSuccess: onSuccess,
        postProcessError: onError,
    }
});

export const markNotificationViewed = (
    uuid: string,
    onSuccess: (response: any) => {},
    onError: (error: any) => {},
) => ({
    type: constants.API,
    payload: {
        method: 'PATCH',
        url: '/notifications/' + uuid + '/',
        data: {status: "viewed"},
        postProcessSuccess: onSuccess,
        postProcessError: onError,
    }
});