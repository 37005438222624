import {Reaction} from "./ReactionInterface";
import {ProfileInterface} from "../../../../../interfaces/ProfileInterface";

import classNames from "classnames";
import {useEffect, useState} from "react";
import EmojiPicker from 'emoji-picker-react';
import pusher from "../../../../../utils/pusher";
import {connect} from "react-redux";
import {
    giveReactionForActivity,
    withdrawnActivityReaction
} from "../../../../../redux/actions/activityReactionsActions";
import {useTranslation} from "react-i18next";

interface PusherReactionEvent {
    reaction: string;
    profile: ProfileInterface;
}

const Reactions = (props: {
    uuid: string;
    items: Reaction[];
    signedInProfile: ProfileInterface;
    dispatchGiveReactionForActivityAction: (uuid: string, data: any, onSuccess: any, onError: any) => {};
    dispatchWithdrawnActivityReactionAction: (uuid: string, data: any, onSuccess: any, onError: any) => {};
}) => {
    const {
        items,
        signedInProfile,
        uuid,
        dispatchGiveReactionForActivityAction,
        dispatchWithdrawnActivityReactionAction
    } = props;
    const [reactions, setReactions] = useState(items);
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const { t, i18n } = useTranslation(['activity']);

    useEffect(() => {
        const channelName = "activities." + uuid + ".reactions";
        const channel = pusher.subscribe(channelName);
        channel.bind("ActivityReactionGiven",(data: PusherReactionEvent) => {
            handleReactionGiven(data.reaction, data.profile);
        })
        channel.bind("ActivityReactionWithdrawn",(data: PusherReactionEvent) => {
            handleReactionWithdrawn(data.reaction, data.profile);
        })

        return (() => {
            pusher.unsubscribe(channelName)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const toggleReaction = (clickedReaction: Reaction) => {
        clickedReaction.profiles.some(profile => profile.uuid === signedInProfile.uuid)
            ? handleDispatchReactionWithdrawn(clickedReaction.reaction)
            : handleDispatchReactionGiven(clickedReaction.reaction)
    }

    const handleDispatchReactionGiven = (givenReaction: string) => {
        dispatchGiveReactionForActivityAction(
            uuid,
            {reaction: givenReaction},
            (response: any) => {handleReactionGiven(givenReaction, signedInProfile)},
            (error: any) => console.log(error),
        );
    }

    const handleDispatchReactionWithdrawn = (withdrawnReaction: string) => {
        dispatchWithdrawnActivityReactionAction(
            uuid,
            {reaction: withdrawnReaction},
            (response: any) => {handleReactionWithdrawn(withdrawnReaction, signedInProfile)},
            (error: any) => console.log(error),
        );
    }

    const handleReactionGiven = (givenReaction: string, profileThatReacted: ProfileInterface) => {
        const givenReactionIndex = reactions.findIndex(reaction => reaction.reaction === givenReaction);

        if (givenReactionIndex === -1) {
            reactions.push({reaction: givenReaction, profiles: [profileThatReacted]})
            setReactions([...reactions])
        } else if(!reactions[givenReactionIndex].profiles.some(profile => profile.uuid === profileThatReacted.uuid)) {
            reactions[givenReactionIndex].profiles.push(profileThatReacted);
            setReactions([...reactions]);
        }
    }

    const handleReactionWithdrawn = (withdrawnReaction: string, profileThatReacted: ProfileInterface) => {
        const withdrawnReactionIndex = reactions.findIndex(reaction => reaction.reaction === withdrawnReaction);

        if (
            withdrawnReactionIndex === -1
            || !reactions[withdrawnReactionIndex].profiles.some(profile => profile.uuid === profileThatReacted.uuid)
        ) {
            return
        }

        const updatedProfiles = reactions[withdrawnReactionIndex].profiles.filter(profile => profile.uuid !== profileThatReacted.uuid)

        reactions[withdrawnReactionIndex].profiles = updatedProfiles

        if (updatedProfiles.length === 0) {
            const updatedReactions = reactions.filter(reaction => reaction.reaction !== withdrawnReaction);
            setReactions(updatedReactions);
        } else {
            setReactions([...reactions]);
        }
    }

    const singleReaction = (reaction: Reaction) => {
        const given = reaction.profiles.some(profile => profile.uuid === signedInProfile.uuid)

        return <div
            key={reaction.reaction}
            className={classNames("pill", {"selected": given})}
        >
            <span onClick={() => toggleReaction(reaction)} className={"ico"}>{reaction.reaction}</span>
            <span className={"label"}>{"" + reaction.profiles.length}</span>
        </div>
    }

    const groupedReactions = () => {
        const total = reactions.reduce((accumulator, object) => {
            return accumulator + object.profiles.length;
        }, 0);

        return <div
            className={classNames("pill", "ico-group")}
        >
            {reactions.map((reaction) => {
                const given = reaction.profiles.some(profile => profile.uuid === signedInProfile.uuid)
                return <span
                    key={reaction.reaction}
                    onClick={() => toggleReaction(reaction)} title={"" + reaction.profiles.length}
                    className={classNames("ico", {"selected": given})}
                >
                    {reaction.reaction}
                </span>
            })}
            <span className={"label"}>{"" + total}</span>
        </div>
    }

    return <div className={"reactions"}>
        <div
            className={classNames("pill")}
            onClick={() => {setShowEmojiPicker(true)}}
        >
            <span className={"ico"}>+</span>
        </div>

        {reactions.length === 0 && <span>{t("activity:feed.item.reactions.label.noReactionYet")}</span>}

        {showEmojiPicker && <div className={"modal"} style={{position: "absolute", bottom: "0"}}>
            <span onClick={() => setShowEmojiPicker(false)}>x</span>
            <EmojiPicker
                skinTonesDisabled={true}
                onEmojiClick={(emoji) => {handleDispatchReactionGiven(emoji.emoji); setShowEmojiPicker(false)}}
            />
        </div>}

        {reactions.length > 5
            ? groupedReactions()
            : reactions.map((reaction) => singleReaction(reaction))
        }
    </div>
}

const mapDispatchToProps = (dispatch: any) => ({
    "dispatchGiveReactionForActivityAction": (uuid: string, data: any, onSuccess: any, onError: any) => dispatch(giveReactionForActivity(uuid, data, onSuccess, onError)),
    "dispatchWithdrawnActivityReactionAction": (uuid: string, data: any, onSuccess: any, onError: any) => dispatch(withdrawnActivityReaction(uuid, data, onSuccess, onError)),
});

export default connect(null, mapDispatchToProps)(Reactions);