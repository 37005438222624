import "./CookieBox.scss";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import Cookies from "universal-cookie";

const CookieBox = () => {
    const cookies = new Cookies(null, { path: '/' });
    const [accepted, setAccepted] = useState<string | null>(cookies.get('cookies.accepted'));
    const { t } = useTranslation(['cookie']);

    const onCookieAllAcceptClicked = (e: any): void => {
        e.preventDefault();
        setAccepted('all');

        cookies.set('cookies.accepted', 'all', {maxAge: 34560000, sameSite: true});
    }

    const onCookieEssentialAcceptedClicked = (e: any): void => {
        e.preventDefault();
        setAccepted('only-essential');

        cookies.set('cookies.accepted', 'only-essential', {maxAge: 34560000, sameSite: true});
    }

    if (accepted !== undefined) {
        return <></>
    }

    return <>
        <div className={"cookie-box"}>
            <div className={"content"}>
                {t("cookie:text")}
            </div>
            <div className={"actions"}>
                <button onClick={(e: any) => onCookieAllAcceptClicked(e)}>{t("cookie:button.accept.label")}</button>
                <button onClick={(e: any) => onCookieEssentialAcceptedClicked(e)}>{t("cookie:button.decline.label")}</button>
            </div>
        </div>
    </>
}

export default CookieBox;