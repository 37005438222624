import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

const supportedLanguages = ["en", "pl", "de", "sv", "es"];
const namespaces = ["activity", "challenge", "cookie", "discipline", "navBar", "page", "profile", "time"];

const translations = {};

// Load translation files dynamically
supportedLanguages.forEach((lang) => {
    translations[lang] = {};

    namespaces.forEach((namespace) => {
        translations[lang][namespace] = require(`./../locales/${namespace}.${lang}.json`);
    });
});

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: translations,
        fallbackLng: "en",
        debug: false,
        react: {
            useSuspense: false,
        },
        interpolation: {
            escapeValue: false,
        },
        transSupportBasicHtmlNodes: true,
        transKeepBasicHtmlNodesFor: ["br", "strong", "i", "span"],
    }).then(() => {});