import {ReactNode} from "react";
import classNames from "classnames";
import {Link} from "react-router-dom";

import "./LabeledAvatar.scss";
import {Avatar} from "./index";

interface LabeledAvatarProps {
    src?: string | null,
    alt: string
    size?: "size-s" | "size-m" | "size-l" | "size-xl",
    className?: string;
    containerStyle?: object;
    labelStyle?: object;
    imageStyle?: object;
    linkTo?: string;
    children?: ReactNode;
    onClick?: any;
}

const LabeledAvatar = (props: LabeledAvatarProps) => {
    const {
        src,
        alt,
        className,
        size,
        containerStyle,
        labelStyle,
        imageStyle,
        linkTo,
        children,
        onClick
    } = props;

    const label = <span className={classNames("label", size)} style={labelStyle}>{alt}</span>

    return <div className={classNames("avatar-container", className)} style={containerStyle}>
        <Avatar
            src={src}
            alt={alt}
            className={classNames("avatar", size)}
            style={imageStyle}
            linkTo={linkTo}
            onClick={onClick}
        />
        <div className={"details"}>
            {linkTo ? <Link to={linkTo} onClick={onClick}>{label}</Link> : <span onClick={onClick}>{label}</span>}
            {children && <div className={classNames("under-label", size)}>{children}</div>}
        </div>
    </div>
}

export default LabeledAvatar