import Tags from "./Footer/Tags";
import Reactions from "./Footer/Reactions";

import {Reaction} from "./Footer/ReactionInterface";
import {CommentsInterface} from "../../../../interfaces/ActivityInterface";
import {ProfileInterface} from "../../../../interfaces/ProfileInterface";

const ActivityFooter = (props: {
    uuid: string;
    tags: string[];
    reactions: Reaction[],
    comments: CommentsInterface;
    signedInProfile: ProfileInterface
}) => {
    const {
        uuid,
        tags, reactions,
        signedInProfile,
        comments
    } = props;

    return <div className={"activity-item-footer"}>
        <Reactions items={reactions} signedInProfile={signedInProfile} uuid={uuid} />
        <Tags tags={tags.length === 0 ? ["general"] : tags} />
    </div>
}

export default ActivityFooter;