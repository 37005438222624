import React, {useEffect, useRef, useState} from 'react';
import './App.scss';

import "./../_ui/index.scss"
import {Navigate, Route, Routes} from "react-router-dom";
import WallPage from "../../pages/WallPage";
import {connect} from "react-redux";
import {AuthInterface} from "../../interfaces/AuthInterface";
import ProfilePage from "../../pages/ProfilePage";
import LanguageSwitch from "../LanguageSwitch/LanguageSwitch";
import {fetchAuthProfiles, signOutCurrent} from "../../redux/actions/authActions";
import LandingPage from "../../pages/LandingPage/LandingPage";
import SingleActivityPage from "../../pages/ProfilePage/SingleActivityPage";
import CookieBox from "../CookieBox/CookieBox";
import NavBar from "../NavBar/NavBar";
import Settings from "../../pages/Settings";
import SettingsHome from "../../pages/Settings/SettingsHome";
import Account from "../../pages/Settings/Account";
import Sessions from "../../pages/Settings/Sessions";
import Notifications from "../../pages/Settings/Notifications";

function App(props: {auth: AuthInterface, dispatchFetchAuthProfilesAction: any, dispatchSignOutAction: any}) {
    const {
        auth,
        dispatchFetchAuthProfilesAction,
        dispatchSignOutAction,
    } = props;
    const [loading, setLoading] = useState(true);
    const prevAuthRef = useRef<AuthInterface>();

    useEffect(() => {
        if (prevAuthRef.current && prevAuthRef.current.isSignedIn === auth.isSignedIn) {
            return;
        }

        if (auth.isSignedIn) {
            dispatchFetchAuthProfilesAction(
                () => setLoading(false),
                () => dispatchSignOutAction(() => setLoading(false), () => setLoading(false))
            );
        } else {
            setLoading(false);
        }

        prevAuthRef.current = auth;
    }, [dispatchFetchAuthProfilesAction, dispatchSignOutAction, auth]);

    if (loading) {
        return <div className="app-loader">
            <div className="spinner-container">
                <div className="circle"></div>
                <div className="sportzzy-logo"></div>
            </div>
        </div>;
    }

    return (
        <>
            <NavBar />
            <div className={"main-content"}>
                <Routes>
                    <Route path='/hi' element={auth.isVerified ? <Navigate to='/' /> : <LandingPage />} />
                    <Route path='/' element={auth.isVerified ? <WallPage /> : <Navigate to='/hi/' />} />
                    <Route path='/settings/' element={auth.isVerified ? <Settings /> : <Navigate to='/hi' />}>
                        <Route  path='/settings/' element={<SettingsHome />} />
                        <Route path='/settings/account/' element={<Account />} />
                        <Route path='/settings/sessions/' element={<Sessions />} />
                        <Route path='/settings/notifications/' element={<Notifications />} />
                    </Route>
                    <Route path='/challenges/:uuid/' element={<div>challenge</div>} />
                    <Route path='/:slug/activities/:uuid/' element={<SingleActivityPage />} />
                    <Route path='/:slug/' element={<ProfilePage />} />
                </Routes>
            </div>
            <LanguageSwitch />
            <CookieBox />
        </>
    );
}

const mapDispatchToProps = (dispatch: any) => ({
    "dispatchFetchAuthProfilesAction": (
        onSuccess: any,
        onError: any
    ) => dispatch(fetchAuthProfiles(onSuccess, onError)),
    "dispatchSignOutAction":  (onSuccess: any, onError: any) => dispatch(signOutCurrent(onSuccess, onError))
});

const mapStateToProps = (state: any) => ({
    auth: state.auth
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
