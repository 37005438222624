import React, {useEffect, useRef, useState} from "react";
import {fetchActiveTokens, deleteToken} from "../../redux/actions/authActions";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import Moment from "react-moment";
import {Helmet} from "react-helmet-async";
import Panel from "../../components/SportzzyUI/Panel/Panel";

const Sessions = ({dispatchFetchActiveTokens, dispatchDeleteToken}) => {
    const [tokens, setTokens] = useState(null);
    const currentTokensRef = useRef();

    useEffect(() => {
        if (currentTokensRef.current) {
            return;
        }

        dispatchFetchActiveTokens(
            (resp) => setTokens(resp.items),
            (err) => console.log(err)
        );

        currentTokensRef.current = tokens;
    }, [dispatchFetchActiveTokens]);

    const deleteSession = (uuid) => {
        dispatchDeleteToken(
            uuid,
            () => {
                dispatchFetchActiveTokens(
                    (resp) => setTokens(resp.items),
                    (err) => console.log(err)
                );
            }
        );
    }

    if (tokens === null) {
        return <div>loading...</div>;
    }

    return (
        <>
            <Helmet>
                <title>Settings - Sessions | Sportzzy</title>
                <meta name="robots" content='noindex,nofollow' />
            </Helmet>
            <Panel style={{marginBottom: '25px'}} >
                <h2>Current Session</h2>
                <table style={{width: "80%", margin: "25px auto"}}>
                    <thead>
                    <tr>
                        <th>Device</th>
                        <th>Browser</th>
                        <th>Created at</th>
                        <th>Last used at</th>
                        <th>IP</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    { tokens.filter((session) => session.current).map((token) => <tr key={token.uuid}>
                        <td>{token.platform}</td>
                        <td>{token.browser}</td>
                        <td><Moment fromNow={ true } local interval={30000} date={ token.created_at } /></td>
                        <td><Moment fromNow={ true } local interval={30000} date={ token.last_used_at } /></td>
                        <td>{token.last_used_from_ip}</td>
                        <td><button onClick={() => deleteSession(token.uuid)}>sign out</button></td>
                    </tr> ) }
                    </tbody>
                </table>
            </Panel>

            <Panel>
                <h2>Other Devices Sessions</h2>
                <table style={{width: "80%", margin: "25px auto"}}>
                    <thead>
                    <tr>
                        <th>Device</th>
                        <th>Browser</th>
                        <th>Created at</th>
                        <th>Last used at</th>
                        <th>IP</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    { tokens.filter((session) => !session.current).map((token) => <tr key={token.uuid}>
                        <td>{token.platform}</td>
                        <td>{token.browser}</td>
                        <td><Moment fromNow={ true } local interval={30000} date={ token.created_at } /></td>
                        <td><Moment fromNow={ true } local interval={30000} date={ token.last_used_at } /></td>
                        <td>{token.last_used_from_ip}</td>
                        <td><button onClick={() => deleteSession(token.uuid)}>sign out</button></td>
                    </tr> ) }
                    </tbody>
                </table>
            </Panel>
        </>
    );
}

const mapDispatchToProps = dispatch => ({
    "dispatchFetchActiveTokens": (onSuccess, onError) => dispatch(fetchActiveTokens(onSuccess, onError)),
    "dispatchDeleteToken": (uuid, onSuccess, onError) => dispatch(deleteToken(uuid, onSuccess, onError))
});

Sessions.propTypes = {
    dispatchFetchActiveTokens: PropTypes.func.isRequired
};

export default connect(null, mapDispatchToProps)(Sessions);