import {connect} from "react-redux";
import {Helmet} from "react-helmet-async";
import {fetchAuthProfiles, signIn, signOutCurrent} from "../../redux/actions/authActions";
import {useForm} from "react-hook-form";

import "./LandingPage.scss"
import {useTranslation} from "react-i18next";
import {Slogan} from "../../components/SportzzyUI/Branding";

const LandingPage = (props: {dispatchSignInAction: any, dispatchFetchAuthProfilesAction: any, dispatchSignOutAction: any}) => {
    const {
        dispatchSignInAction,
        dispatchFetchAuthProfilesAction,
        dispatchSignOutAction
    } = props;

    const {
        register,
        handleSubmit,
        setError,
        formState: { errors }
    } = useForm();

    const { t } = useTranslation(['page']);

    const onSubmit = (data: any) => {
        dispatchSignInAction(data, () => handleSignInSuccess, (err: any) => console.log(err));
    }

    const handleSignInSuccess = () => {
        dispatchFetchAuthProfilesAction(
            () => dispatchSignOutAction(() => {}, () => {})
        );
    }

    return <>
        <Helmet>
            <title>{t("page:landingPage.title")}</title>
            <meta name="robots" content="index,follow" />
            <meta name="description" content={t("page:landingPage.description")} />
            <meta name="keywords" content={t("page:landingPage.keywords")} />
        </Helmet>
        <div>
            <section id="video-section">
                <video autoPlay loop muted>
                    <source src="https://sportzzy.com/video/rollerblades.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                </video>
                <div>
                    <Slogan firstLine={"stay active"} secondLine={"join our sport community"} />
                    <div id="login-form">
                        <h2>Login</h2>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <input
                                type={"email"}
                                {...register(
                                    "email",
                                    {
                                        required: 'auth:signIn.fields.email.errors.isRequired',
                                        maxLength: 64
                                    }
                                )}
                            />
                            <input
                                type={"password"}
                                {...register(
                                    "password",
                                    {
                                        required: 'auth:signIn.fields.password.errors.isRequired',
                                        maxLength: 64
                                    }
                                )}
                            />
                            <input type="submit" value={"sign in"} />
                        </form>
                    </div>
                </div>
            </section>

            <section id="content-section">
                <h1>Second Section with Green Background</h1>
                <p>This is the second section with a green background.</p>
            </section>
        </div>
    </>
}

const mapDispatchToProps = (dispatch: any) => ({
    "dispatchSignInAction": (data: {email: string, password: string}, onSuccess: any, onError: any) => dispatch(signIn(data, onSuccess, onError)),
    "dispatchFetchAuthProfilesAction": (
        onSuccess: any,
        onError: any
    ) => dispatch(fetchAuthProfiles(onSuccess, onError)),
    "dispatchSignOutAction":  (onSuccess: any, onError: any) => dispatch(signOutCurrent(onSuccess, onError))
});

export default connect(null, mapDispatchToProps)(LandingPage);