import {profileActivityLink} from "../../../../utils/linkUtils";
import {Link} from "react-router-dom";
import React from "react";
import {ProfileInterface} from "../../../../interfaces/ProfileInterface";
import {useTranslation} from "react-i18next";

const OnWallActivityPublished = (props: {
    notification: {activity?: {uuid: string}, profile: ProfileInterface},
    signedInProfile: ProfileInterface,
    hideContentsHandler: () => void
}) => {
    const {
        notification,
        signedInProfile,
        hideContentsHandler
    } = props
    const { t } = useTranslation(['navBar']);

    if (notification.activity) {
        return <Link
            onClick={hideContentsHandler}
            to={profileActivityLink(
                signedInProfile.slug ?? "",
                notification.activity.uuid
            )}
        >
            {t(
                "navBar:notifications.onWallActivityPublished",
            )}
        </Link>
    }

    return <></>
}

export default OnWallActivityPublished;