import {ActivityInterface} from "../../interfaces/ActivityInterface";
import {ProfileInterface} from "../../interfaces/ProfileInterface";

import { Mention, MentionsInput } from "react-mentions";
import React, {useRef, useState} from "react";

import {connect} from "react-redux";
import {fetchProfilesByPhrase} from "../../redux/actions/profileActions";
import {fetchSingleActivity, publishActivity, publishInProfileActivity} from "../../redux/actions/activityActions";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {faLocationDot, faLock, faLockOpen, faPhotoFilm, faUserGroup} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Avatar, LabeledAvatar} from "../_ui/Avatar";
import Challenge from "./Form/Challenge";

interface MentionSuggestion {
    display: string;
    id: string;
    profile: ProfileInterface;
}

const ActivityPublishForm = (props: {
    signedInProfile: ProfileInterface,
    profile?: ProfileInterface | null,
    dispatchFetchProfilesByPhraseAction: (
        phrase: string,
        onSuccess: any,
        onError: any,
        cancelToken?: any
    ) => void,
    dispatchPublishActivityAction: (
        data: {
            "visibility": string,
            "tags": string[],
            "contents": {"text": string}
        },
        onSuccess: any,
        onError: any
    ) => void,
    dispatchPublishInProfileActivityAction: (
        profileUuid: string,
        data: {
            "visibility": string,
            "tags": string[],
            "contents": {"text": string}
        },
        onSuccess: any,
        onError: any
    ) => void,
    activityPublishedHandler: (
        activity: ActivityInterface
    ) => void,
    dispatchFetchSingleActivityAction: (
        uuid: string,
        onSuccess: any,
        onError: any
    ) => void
}) => {
    const {
        signedInProfile,
        profile,
        dispatchFetchProfilesByPhraseAction,
        dispatchPublishActivityAction,
        dispatchPublishInProfileActivityAction,
        dispatchFetchSingleActivityAction,
        activityPublishedHandler,
    } = props

    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
        reset,
        setValue,
        clearErrors
    } = useForm();

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isExpended, setIsExpended] = useState(false);
    const [withChallenge, setWithChallenge] = useState(false);
    const [withGallery, setWithGallery] = useState(false);
    const [draggingOverDropArea, setDraggingOverDropArea] = useState(false);

    const inputFile = useRef<HTMLInputElement>(null);

    const { t } = useTranslation(['activity', 'profile']);

    const handlePublishActivitySubmit = (activity: any) => {
        setIsSubmitting(true);

        if (typeof profile?.uuid === 'string') {
            dispatchPublishInProfileActivityAction(
                profile.uuid,
                activity,
                (response: {data: {uuid: string}}) => handlePublishActivitySuccess(response),
                (error: {errors: {field: string, message: {raw: string}}[]}) => handlePublishActivityErrors(error)
            );
        } else {
            dispatchPublishActivityAction(
                activity,
                (response: {data: {uuid: string}}) => handlePublishActivitySuccess(response),
                (error: {errors: {field: string, message: {raw: string}}[]}) => handlePublishActivityErrors(error)
            );
        }
    }

    const handleResetForm = () => {
        setContentsText("");
        setWithChallenge(false);
        setWithGallery(false);
        reset();
        setIsSubmitting(false);
        setIsExpended(false);
    }

    const handlePublishActivitySuccess = (response: {data: {uuid: string}}): void => {
        const afterActivityFetchedSuccess = (activity: ActivityInterface): void => {
            handleResetForm();
            activityPublishedHandler(activity);
        }

        const afterActivityFetchedError = (error: any): void => {
            setIsSubmitting(false);
            console.log(error);
        }

        dispatchFetchSingleActivityAction(
            response.data.uuid,
            (response: ActivityInterface) => afterActivityFetchedSuccess(response),
            (error: any) => afterActivityFetchedError(error)
        )
    }

    const handlePublishActivityErrors = (response: {errors: {field: string, message: {raw: string}}[]}): void => {
        response.errors.forEach((error) => {
            setError(error.field, { type: "custom", message: error.message.raw }, { shouldFocus: true });
        });

        setIsSubmitting(false);
    }

    const fetchProfiles = (query: string, callback: any) => {
        if (!query) return;

        const mapProfileToSuggestion = (profile: ProfileInterface): MentionSuggestion => ({
            display: profile.name,
            id: profile.slug,
            profile: profile,
        });

        new Promise<{ display: string; id: string }[]>((resolve, reject) => {
            dispatchFetchProfilesByPhraseAction(
                query,
                (response: { items: any[] }) => {
                    const items: MentionSuggestion[] = response.items.map(mapProfileToSuggestion);
                    resolve(items);
                },
                (error: any) => {
                    reject(error);
                }
            );
        }).then(callback)
    }

    const [contentsText, setContentsText] = useState("");

    const renderSuggestion = (entry: any, search: any, highlightedDisplay: any, index: any, focused: any) => {
        return <div className={"mention-suggestion"}>
            <LabeledAvatar size={"size-s"} alt={entry.profile.name} src={entry.profile.avatar?.url}>
                {entry.profile.city && <span className={"city"}>
                    <FontAwesomeIcon icon={faLocationDot} /> {entry.profile.city}
                </span>}
                {entry.profile.followingStatus === 'confirmed' && <span className={"following"}>
                    <FontAwesomeIcon icon={faUserGroup} /> {t("profile:item.followingStatus.confirmed." + entry.profile?.type)}
                </span>}
                {entry.profile.uuid === signedInProfile.uuid && <span className={"following"}>
                    <FontAwesomeIcon icon={faUserGroup} /> {t("profile:item.followingStatus.you")}
                </span>}
            </LabeledAvatar>
        </div>
    }

    const publishActivityForm = <form
        style={{background: "#fff", padding:"15px", borderRadius: "20px"}}
        onSubmit={handleSubmit(handlePublishActivitySubmit)}
        onDragOver={() => setWithGallery(true)}
    >
        <div className={"activity-item-header"}>
            <LabeledAvatar
                src={signedInProfile.avatar !== undefined ? signedInProfile.avatar.url : ""}
                alt={signedInProfile.name}
            >
                <div>
                    <div>
                        <label htmlFor="visibilityPrivate">
                            <FontAwesomeIcon icon={faLock} title={t("activity:form.visibility.private")} />
                            <input
                                disabled={isSubmitting}
                                {...register("visibility")}
                                type="radio"
                                value="private"
                                id="visibilityPrivate"
                            />
                        </label>
                        <label htmlFor="visibilityPublic">
                            <input
                                disabled={isSubmitting}
                                {...register("visibility")}
                                type="radio"
                                value="public"
                                defaultChecked={true}
                                id="visibilityPublic"
                            />
                            <FontAwesomeIcon icon={faLockOpen} title={t("activity:form.visibility.public")} />
                        </label>
                    </div>

                </div>
            </LabeledAvatar>
        </div>
        <div className={'publish-form-content-text'}>
            <MentionsInput
                {...register(
                    "contents[text]",
                    {
                        maxLength: 4096
                    }
                )}
                disabled={isSubmitting}
                autoFocus={true}
                placeholder={t("activity:form.contents.text.placeholder")}
                value={contentsText}
                onChange={(e): void => {
                    setContentsText(e.target.value);
                    setValue("contents[text]", e.target.value);
                    clearErrors("contents");
                }}
                style={
                    {
                        input: {
                            height: 80,
                            overflow: "auto",
                            border: 0,
                            fontSize: "20px",
                            lineHeight: "150%",
                            outline: "none"
                        },
                        highlighter: {
                            height: 80,
                            overflow: "hidden",
                            boxSizing: "border-box",
                        }
                    }
                }
            >
                <Mention
                    trigger={"@"}
                    data={fetchProfiles}
                    markup="@{{profile||__id__||__display__}}"
                    renderSuggestion={renderSuggestion}
                    style={{textDecoration: "underline"}}
                    appendSpaceOnAdd
                />
            </MentionsInput>
            {errors.contents && <span>{t(errors.contents?.message)}</span>}
        </div>
        {withChallenge && <Challenge />}
        {withGallery && <div
            style={{boxSizing: "border-box", textAlign: "center", fontSize: "20px", lineHeight: "150%", color: draggingOverDropArea ? "#26e567" : "#fff", background: "#e1e1e1", margin: "0 -15px 0 -15px", padding: "15px"}}
            onDragOver={(e) => {e.preventDefault(); setDraggingOverDropArea(true)}}
            onDragEnter={(e) => {e.preventDefault(); setDraggingOverDropArea(true)}}
            onDragLeave={(e) => {e.preventDefault(); setDraggingOverDropArea(false)}}
            onDrop={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setDraggingOverDropArea(false);
                let dt = e.dataTransfer;

                if (inputFile?.current !== null) {
                    inputFile.current.files = dt.files;
                }
            }}
        >
            <input
                disabled={isSubmitting}
                type="file"
                multiple={true}
                name={"gallery[]"}
                ref={inputFile}
                style={{display: "none"}}
                accept={"image/*"}
                onChange={(e) => {
                    if (e.target?.files === null) return;

                    const files = Array.from(e.target.files);
                    const promises = files.map((file) => {
                        return new Promise((resolve, reject) => {
                            const reader = new FileReader();
                            reader.onload = (event) => {
                                resolve(event.target?.result);
                            };
                            reader.onerror = (error) => {
                                reject(error);
                            };
                            reader.readAsDataURL(file);
                        });
                    });

                    Promise.all(promises)
                        .then((base64Images) => {
                            setValue("contents[multimedia][]", base64Images);
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                }}
            />

            <div>
                <div style={{padding: "20px 0 20px", fontSize: "40px"}}>
                    <FontAwesomeIcon icon={faPhotoFilm} />
                </div>
                {t("activity:form.contents.gallery.dropFilesHere")}<br/>
                {t("activity:form.contents.gallery.or")}<br/>
                <button
                    disabled={isSubmitting}
                    onClick={(e) => {e.preventDefault(); inputFile?.current?.click();}}
                >
                    {t("activity:form.contents.gallery.selectFromYourDevice")}
                </button>
            </div>
        </div>}
        <div style={{padding: "15px 15px 0", borderTop: "solid 1px #ccc", margin: "0 -15px 0 -15px", display: "flex"}}>
            <div style={{width: "50%"}}>
                <button
                    disabled={isSubmitting}
                    onClick={(e) => {
                        e.preventDefault();
                        setWithChallenge(!withChallenge);
                    }}

                    style={{background: withChallenge ? "#26e567" : ""}}
                >
                    Challenge
                </button>
                <button
                    disabled={isSubmitting}
                    onClick={(e) => {
                        e.preventDefault();
                        setWithGallery(!withGallery);
                    }}

                    style={{background: withGallery ? "#26e567" : ""}}
                >
                    Gallery
                </button>
            </div>
            <div style={{width: "50%", textAlign:"right"}}>
                <input type='reset' value='cancel' disabled={isSubmitting} onClick={handleResetForm} />
                <input type='submit' value='publish' disabled={isSubmitting} />
            </div>
        </div>
    </form>

    return <>
        {isExpended
            ? publishActivityForm
            : <div style={{padding: "15px", background: "#fff", display: "flex", flexWrap: "wrap", alignItems: "center", borderRadius: "20px"}}>
                <Avatar src={signedInProfile.avatar?.url ?? ""} alt={signedInProfile.name} />
                <div
                    onClick={() => setIsExpended(true)}
                    onDragOver={() => {setIsExpended(true); setWithGallery(true)}}
                    style={{
                        flexGrow: "1",
                        marginLeft: "5px",
                        fontSize: "20px",
                        lineHeight: "150%",
                        color: "#ccc",
                        cursor: "text",
                        maxWidth: "calc(100% - 47px)",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis"
                    }}
                >
                    {t("activity:form.contents.text.placeholder")}
                </div>
            </div>
        }
    </>
}

const mapDispatchToProps = (dispatch: any) => ({
    "dispatchFetchProfilesByPhraseAction": (
        phrase: string,
        onSuccess: any,
        onError: any,
        cancelToken?: any
    ) => dispatch(fetchProfilesByPhrase(phrase, onSuccess, onError, cancelToken)),
    "dispatchPublishActivityAction": (
        data: {"visibility": string, "tags": string[], "contents": {"text": string}},
        onSuccess: any,
        onError: any
    ) => dispatch(publishActivity(data, onSuccess, onError)),
    "dispatchPublishInProfileActivityAction": (
        profileUuid: string,
        data: {"visibility": string, "tags": string[], "contents": {"text": string}},
        onSuccess: any,
        onError: any
    ) => dispatch(publishInProfileActivity(profileUuid, data, onSuccess, onError)),
    "dispatchFetchSingleActivityAction": (
        uuid: string,
        onSuccess: any,
        onError: any
    ) => dispatch(fetchSingleActivity(uuid, onSuccess, onError))
});

export default connect(null, mapDispatchToProps)(ActivityPublishForm);