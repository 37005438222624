import {ProfileInterface} from "../../../interfaces/ProfileInterface";
import {useTranslation} from "react-i18next";
import classNames from "classnames";

const NoMoreContent = (props: {profile?: ProfileInterface}) => {
    const { t, i18n } = useTranslation(['activity']);

    return <div className={classNames("no-more-content", "beautifier-white-bottom-right")}>
        {props.profile
            ? t("activity:feed.noMoreContent.profile", {"name": props.profile.name})
            : t("activity:feed.noMoreContent.general")
        }
    </div>
}

export default NoMoreContent;