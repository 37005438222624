import Panel from "../../components/SportzzyUI/Panel/Panel";
import {Helmet} from "react-helmet-async";
import React, {useEffect, useRef, useState} from "react";
import {useForm} from "react-hook-form";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {changePassword, fetchAuthProfiles} from "../../redux/actions/authActions";
import {t} from "i18next";
import Modal from "../../components/SportzzyUI/Modal/Modal";

const Account = ({dispatchFetchAuthProfiles, dispatchChangePassword}) => {
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
        reset
    } = useForm();

    const [user, setUser] = useState(null);
    const currentUserRef = useRef();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showChangePassword, setShowChangePassword] = useState(false);

    useEffect(() => {
        if (currentUserRef.current) {
            return;
        }

        dispatchFetchAuthProfiles(
            (resp) => setUser(resp),
            (err) => console.log(err)
        );

        currentUserRef.current = user;
    }, [dispatchFetchAuthProfiles]);

    const onSubmit = data => {
        setIsSubmitting(true);

        dispatchChangePassword(
            data,
            (response) => handleChangePasswordSuccess(response),
            (err) => handleChangePasswordError(err),
        );
    }

    const handleChangePasswordSuccess = (response) => {
        handleReset();
    }

    const handleChangePasswordError = (response) => {
        setIsSubmitting(false);
        response.errors.forEach((error) => {
            setError(error.field, { type: "custom", message: error.message.raw }, { shouldFocus: true });
        });
    }

    const handleReset = () => {
        reset();
        setIsSubmitting(false);
        setShowChangePassword(false)
    }

    if (user === null) {
        return <div>loading...</div>;
    }

    return <>
        <Helmet>
            <title>Settings - Account | Sportzzy</title>
            <meta name="robots" content='noindex,nofollow' />
        </Helmet>

        <div>
            <div>
                <Panel style={{marginBottom: '25px'}} >
                    <h2>User details</h2>
                    <div>
                        <span style={{display: "inline-block", marginRight: '15px', color: '#ccc'}}>Email:</span>
                        <strong>{user.email}</strong>
                    </div>
                    <div>
                        <span style={{display: "inline-block", marginRight: '15px', color: '#ccc'}}>Password:</span>
                        <button className='success small' onClick={() => setShowChangePassword(!showChangePassword)}>Change</button>
                    </div>
                    { showChangePassword && <Modal closeHandler={handleReset} title='Change password'>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div>
                                <label>current password:</label>
                                <input
                                    type="password"
                                    disabled={isSubmitting}
                                    {...register(
                                        "currentPassword",
                                        {
                                            maxLength: 256
                                        }
                                    )}
                                />
                                {errors.currentPassword && <span>{t(errors.currentPassword?.message)}</span>}
                            </div>
                            <div>
                                <label>new password:</label>
                                <input
                                    type="password"
                                    autoComplete="new-password"
                                    disabled={isSubmitting}
                                    {...register(
                                        "newPassword",
                                        {
                                            maxLength: 256
                                        }
                                    )}
                                />
                                {errors.newPassword && <span>{t(errors.newPassword?.message)}</span>}
                            </div>
                            <div className='action-buttons'>
                                <input type='reset' value='cancel' className='ui-btn none' disabled={isSubmitting} onClick={handleReset} />
                                <input type='submit' value='change' className='ui-btn success' disabled={isSubmitting} />
                            </div>
                        </form>
                    </Modal> }
                </Panel>
                <Panel>
                    <h2>Profiles</h2>
                    <div>
                        {user.profiles.map((profile) => <div key={profile.uuid}>{profile.name}</div>)}
                    </div>
                </Panel>
            </div>
        </div>
    </>
}

const mapDispatchToProps = dispatch => ({
    "dispatchChangePassword": (data, onSuccess, onError) => dispatch(changePassword(data, onSuccess, onError)),
    "dispatchFetchAuthProfiles": (onSuccess, onError) => dispatch(fetchAuthProfiles(onSuccess, onError))
});

Account.propTypes = {
    dispatchChangePassword: PropTypes.func.isRequired,
    dispatchFetchAuthProfiles: PropTypes.func.isRequired
};

export default connect(null, mapDispatchToProps)(Account);