import React from "react";

import './Slogan.scss';
import classNames from "classnames";

const Slogan = (props: { firstLine: string, secondLine?: string, style?: any, className?: string }) => {
    const {
        firstLine,
        secondLine,
        style,
        className
    } = props;

    const sloganClass = {
        'ui-slogan': true
    };

    return (
        <h2 className={classNames(sloganClass, className)} style={style}>
            <span
                className={"first-line"}
                dangerouslySetInnerHTML={{ __html: firstLine.replace('i', '<span class="i">i</span>') }}
            />
            <span className="second-line">{ secondLine }</span>
        </h2>
    );
}

export default Slogan;