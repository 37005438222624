import React from "react";
import {useTranslation} from "react-i18next";
import {ProfileInterface} from "../../../interfaces/ProfileInterface";
import {AuthInterface} from "../../../interfaces/AuthInterface";
import {signOutCurrent} from "../../../redux/actions/authActions";
import {connect, useDispatch} from "react-redux";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAddressCard, faArrowsRotate, faDoorClosed, faScrewdriverWrench} from "@fortawesome/free-solid-svg-icons";
import {profileLink} from "../../../utils/linkUtils";

const Menu = (props: {
    auth: AuthInterface,
    hideContentsHandler: () => void,
    dispatchSignOutAction: any
}) => {
    const {
        auth,
        dispatchSignOutAction,
        hideContentsHandler
    } = props;
    const dispatch = useDispatch();
    const { t } = useTranslation(['navBar']);

    return <ul className={"beautifier-white-bottom-right"}>
        <li>
            <div className={"section"}>
                <span className={"section-header"}>
                    <FontAwesomeIcon icon={faAddressCard} /> {t("navBar:menu.profiles")}
                </span>
                <ul>
                {auth.profiles.length > 1 && auth.profiles.map((profile: ProfileInterface) => <li key={profile.uuid}>
                        <Link
                            // size={"size-s"}
                            // alt={profile.name} src={profile.avatar?.url}
                            to={profileLink(profile.slug)}
                            onClick={hideContentsHandler}
                        >
                            {profile.name}
                        </Link>
                        {auth.currentProfile?.uuid !== profile.uuid && <FontAwesomeIcon
                            onClick={() => {
                                hideContentsHandler();
                                dispatch({type: 'SWITCH_PROFILE', payload: {uuid: profile.uuid}});
                            }}
                            icon={faArrowsRotate}
                        />}
                    </li>)}
                </ul>
            </div>
        </li>
        <li>
            <Link to={"/settings/"} onClick={hideContentsHandler}>
                <FontAwesomeIcon icon={faScrewdriverWrench} /> {t("navBar:menu.settings")}
            </Link>
        </li>
        <li>
            <Link
                to={"/"}
                onClick={(e: any) => {
                    e.preventDefault();
                    hideContentsHandler();
                    dispatchSignOutAction(() => {}, () => {})
                }
            }>
                <FontAwesomeIcon icon={faDoorClosed} /> {t("navBar:menu.signOut")}
            </Link>
        </li>
    </ul>;
}

const mapDispatchToProps = (dispatch: any) => ({
    "dispatchSignOutAction":  (onSuccess: any, onError: any) => dispatch(signOutCurrent(onSuccess, onError))
});

export default connect(null, mapDispatchToProps)(Menu);