import {ProfileInterface} from "../../../../../interfaces/ProfileInterface";
import {useState} from "react";
import {useOutsideClick} from "../../../../../hooks/useOutsideClick";
import {useTranslation} from "react-i18next";
import {deleteActivity, reportActivity} from "../../../../../redux/actions/activityActions";
import {connect} from "react-redux";
import classNames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEyeSlash, faHandcuffs, faLink, faPenToSquare, faTrash} from "@fortawesome/free-solid-svg-icons";
import {profileActivityLink} from "../../../../../utils/linkUtils";

const ActivityTools = (props: {
    uuid: string;
    author: ProfileInterface;
    signedInProfile?: ProfileInterface | null;
    beforeActivityDeletedHandler: () => void;
    activityDeletedHandler: (uuid: string) => void;
    setToEditModeHandler: () => void;
    dispatchDeleteActivityAction: (uuid: string, onSuccess: any, onError: any) => {};
    dispatchReportActivityAction: (uuid: string, data: {description: string}, onSuccess: any, onError: any) => {};
}) => {
    const {
        uuid,
        author,
        signedInProfile,
        beforeActivityDeletedHandler,
        activityDeletedHandler,
        setToEditModeHandler,
        dispatchDeleteActivityAction,
        dispatchReportActivityAction
    } = props
    const [showTools, setShowTools] = useState(false);
    const [reversed, setReversed] = useState(false);

    const toolsRef = useOutsideClick(() => {if (showTools) setShowTools(false)});
    const { t} = useTranslation(['activity']);
    const directLink = profileActivityLink(author.slug, uuid, true);

    const onShowToolsClicked = () => {
        const top = toolsRef.current?.getBoundingClientRect().top

        if (top) {
            const distanceToBottom = window.innerHeight - top;
            setReversed(distanceToBottom < 160);
        }

        setShowTools(true);
    }

    const onAnyClicked = (e: any) => {
        e.preventDefault();
        setShowTools(false);
    }

    const onDeleteActivityClicked = (e: any, uuid: string) => {
        onAnyClicked(e);

        //todo replace with modal
        if (!window.confirm("Are you sure you want to delete this activity")) {
            return
        }

        beforeActivityDeletedHandler()

        dispatchDeleteActivityAction(
            uuid,
            () => {
                if (activityDeletedHandler) {
                    activityDeletedHandler(uuid);
                }
            },
            (error: any) => console.log(error)

        )
    }

    const onCopyDirectLinkClicked = (e: any) =>{
        onAnyClicked(e);

        navigator.clipboard.writeText(directLink).then(() => console.log(directLink));
    }

    const onReportClicked = (e: any) => {
        onAnyClicked(e);

        //todo replace with modal
        let description = prompt("Please tell us why you are reporting this activity");
        if (description === null) {
            return
        }

        dispatchReportActivityAction(
            uuid,
            {description: description},
            () => {},
            (error: any) => console.log(error)

        )
    }

    const onHideClicked = (e: any) => {
        onAnyClicked(e);
    }

    const onEditClicked = (e: any) => {
        onAnyClicked(e);
        setToEditModeHandler();
    }

    return <>
            <div className={"activity-item-tools"} ref={toolsRef}>
            <div
                className="tools-indicator"
                onClick={onShowToolsClicked}
                title={t("activity:feed.item.tools.title")}
            />
            {showTools && <div
                className={classNames("dropdown", "shadow", {open: showTools, reversed: reversed})}
            >
                <div className={"dropdown-header"}>
                    <span className={"title"}>{t("activity:feed.item.tools.title")}</span>
                    <span style={{marginLeft: "auto", cursor: "pointer"}} onClick={() => setShowTools(false)}>x</span>
                </div>
                <ul>
                    {signedInProfile && signedInProfile.uuid === author.uuid && <li>
                        <a href={"/"} onClick={(e) => onDeleteActivityClicked(e, uuid)}>
                            <FontAwesomeIcon icon={faTrash} /> {t("activity:feed.item.tools.delete")}
                        </a>
                    </li>}
                    {1 !== 1 && signedInProfile && signedInProfile.uuid === author.uuid && <li>
                        <a href={"/"} onClick={(e) => onEditClicked(e)}>
                            <FontAwesomeIcon icon={faPenToSquare} /> {t("activity:feed.item.tools.edit")}
                        </a>
                    </li>}
                    {signedInProfile && signedInProfile.uuid !== author.uuid && <li>
                        <a href={"/"} onClick={(e: any) => onHideClicked(e)}>
                            <FontAwesomeIcon icon={faEyeSlash} /> {t("activity:feed.item.tools.hide")}
                        </a>
                    </li>}
                    {signedInProfile && signedInProfile.uuid !== author.uuid && <li>
                        <a href={"/"} onClick={(e: any) => onReportClicked(e)}>
                            <FontAwesomeIcon icon={faHandcuffs} /> {t("activity:feed.item.tools.report")}
                        </a>
                    </li>}
                    <li>
                        <a href={"/"} onClick={(e: any) => onCopyDirectLinkClicked(e)}>
                            <FontAwesomeIcon icon={faLink} /> {t("activity:feed.item.tools.copyLink")}
                        </a>
                    </li>
                </ul>
            </div>}
        </div>
    </>
}

const mapDispatchToProps = (dispatch: any) => ({
    "dispatchDeleteActivityAction": (uuid: string, onSuccess: any, onError: any) => dispatch(deleteActivity(uuid, onSuccess, onError)),
    "dispatchReportActivityAction": (uuid: string, data: {description: string}, onSuccess: any, onError: any) => dispatch(reportActivity(uuid, data, onSuccess, onError)),
});

export default connect(null, mapDispatchToProps)(ActivityTools);