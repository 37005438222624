import * as constants from './../constants';
export const fetchFilteredDisciplines = (
    params: {search?: {phrase?: string|null}, perPage: number, sort?: {name?: 'asc' | 'desc'}},
    onSuccess: any,
    onError: any
) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: '/disciplines/',
        params: params,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});