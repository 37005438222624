import axios, { AxiosRequestConfig } from "axios";
import { Dispatch, Middleware, MiddlewareAPI } from "redux";
import * as constants from "./constants";
import { stringify } from "qs";
import unset from "react-hook-form/dist/utils/unset";

interface ApiActionPayload {
    url: string;
    method: string;
    success?: (data: any) => any;
    data?: any;
    params?: any;
    postProcessSuccess?: (data: any) => any;
    postProcessError?: (error: any) => any;
}

export const apiMiddleware: Middleware =
    ({ dispatch, getState }: MiddlewareAPI) =>
        (next: Dispatch) =>
            async (action: { type: string; payload: ApiActionPayload }) => {
                if (!action || action.type !== constants.API) return next(action);

                const prepareData = (data: any): any => {
                    if (!data) return null;

                    for (const propName in data) {
                        if (data[propName] === "") {
                            data[propName] = null;
                        } else if (data[propName] === undefined) {
                            delete data[propName];
                        } else if (typeof data[propName] === "object") {
                            data[propName] = prepareData(data[propName]);
                        }
                    }

                    return data;
                };

                const BASE_API_URL = process.env.REACT_APP_API_URL;
                const {auth} = getState();

                const {
                    url,
                    method,
                    success,
                    data,
                    params,
                    postProcessSuccess,
                    postProcessError,
                } = action.payload;

                if (auth.token && auth.tokenType) {
                    axios.defaults.headers.common["Authorization"] = `${auth.tokenType} ${auth.token}`;
                    delete axios.defaults.headers.common["X-Profile"];
                    if (auth.currentProfile) {
                        axios.defaults.headers.common["X-Profile"] = `${auth.currentProfile.uuid}`;
                    }
                } else {
                    delete axios.defaults.headers.common["Authorization"];
                }

                axios.defaults.headers.common["Accept-Language"] = localStorage.getItem(
                    "i18nextLng"
                );

                const requestConfig: AxiosRequestConfig = {
                    method,
                    url: BASE_API_URL + url,
                    data: prepareData(data),
                    params: params ? prepareData(params) : null,
                    paramsSerializer: (params) => stringify(params),
                };

                try {
                    const response = await axios(requestConfig);
                    if (success) dispatch(success(response.data));
                    if (postProcessSuccess) postProcessSuccess(response.data);
                } catch (err: any) {
                    if (!err.response) {
                        // console.warn(err);
                    } else if (err.response.status === 401) {
                        dispatch({type: constants.CLEAR_AUTH});
                        if (postProcessError) postProcessError(err.response.data);
                    } else if (err.response.status >= 400 && err.response.status < 500) {
                        // todo: handle 400s
                        // if (err.response.status === 409) dispatch(addFlashMessage(err.response.data.message, "error"));
                        // if (err.response.status === 403) dispatch(addFlashMessage(err.response.data.message, "error"));
                        if (postProcessError) postProcessError(err.response.data);
                    } else if (err.response.status >= 500) {
                        // todo: handle 500
                        // dispatch(addFlashMessage(err.response.data.message ?? "Something went wrong", "error"));
                    }
                }
            };