import {ProfileInterface} from "../interfaces/ProfileInterface";

export const nameInstrumental = (profile: ProfileInterface, language: string|"pl"|"en"|"de"|"es"|"sv") => {
    if (profile.type === "commercial") return profile.name;

    const firstName = profile.name.split(' ')[0];

    if (language !== "pl") return firstName;

    const firstNameToLower = firstName.toLowerCase();

    if (firstNameToLower === 'beatrycze') return firstName;
    if (firstNameToLower.charAt(firstName.length - 1) === 'ś') return firstName.slice(0, -1) + 'siem'; // Piotruś -> Piotrusiem
    if (firstNameToLower.substring(firstName.length - 4) === 'ciek') return firstName.slice(0, -4) + 'ćkiem'; // Maciek -> Maćkiem
    if (firstNameToLower.substring(firstName.length - 2) === 'ek') return firstName.slice(0, -2) + 'kiem'; // Marek -> Markiem, Zbyszek -> Zbyszkiem
    if (firstNameToLower.substring(firstName.length - 2) === 'eł') return firstName.slice(0, -2) + 'łem'; // Paweł -> Pawłem, Gaweł -> Gawłem
    if (firstNameToLower.charAt(firstName.length - 1) === 'k') return firstName + 'iem'; // Henryk -> Henrykiem, Patryk -> Patrykiem}
    if (firstNameToLower.charAt(firstName.length - 1) === 'a') return firstName.slice(0, -1) + 'ą'; // Natalia -> Natalią
    if (['o', 'e'].includes(firstNameToLower.charAt(firstNameToLower.length - 1))) return firstName; //Dante, Camilo
    if (['i', 'y'].includes(firstNameToLower.charAt(firstNameToLower.length - 1))) return firstName + 'm'; // Jerzy -> Jerzym, Johnny -> Johnnym, Adamski -> Adamskim

    return firstName + 'em'; // Marcin -> Marcinem, Tomasz => Tomaszem, Maciej -> Maciejem
}