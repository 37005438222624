import {Link} from "react-router-dom";

import "./Tags.scss";

const Tags = (props: {
    tags: string[];
}) => {
    const {tags} = props;

    return <div className={"tags"}>
        {tags.map((tag: string) => <Link className={"tag"} to={"/tags/" + tag + "/"} key={tag}>
            <span>#</span>{tag}
        </Link>)}
    </div>
}

export default Tags;