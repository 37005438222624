import ActivityHeader from "./Item/ActivityHeader";

import "./ActivityFeedItem.scss";
import ActivityContent from "./Item/ActivityContent";
import ActivityFooter from "./Item/ActivityFooter";
import {ActivityInterface} from "../../../interfaces/ActivityInterface";
import {ProfileInterface} from "../../../interfaces/ProfileInterface";
import {useState} from "react";
import classNames from "classnames";

const ActivityFeedItem = (props: {
    activity: ActivityInterface;
    signedInProfile: ProfileInterface;
    activityDeletedHandler: (uuid: string) => void;
}) => {
    const {
        uuid,
        contents,
        tags,
        reactions,
        profile,
        createdAt,
        comments,
        visibility,
        target
    } = props.activity;
    const {
        signedInProfile,
        activityDeletedHandler
    } = props

    const [deleting, setDeleting] = useState(false);
    const [isInEditMode, setIsInEditMode] = useState(false);

    const beforeActivityDeletedHandler = () => setDeleting(true);

    return <div className={classNames("activity-feed-item", {"muted": deleting})}>
        <ActivityHeader
            uuid={uuid}
            author={profile}
            occurredAt={createdAt}
            visibility={visibility}
            target={target}
            signedInProfile={signedInProfile}
            activityDeletedHandler={activityDeletedHandler}
            setToEditModeHandler={() => setIsInEditMode(true)}
            beforeActivityDeletedHandler={beforeActivityDeletedHandler}
        />
        {isInEditMode
            ? <>editing</>
            : <ActivityContent content={contents} />
        }
        <ActivityFooter
            uuid={uuid}
            tags={tags}
            reactions={reactions}
            comments={comments}
            signedInProfile={signedInProfile}
        />
    </div>
}

export default ActivityFeedItem;