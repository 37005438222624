import classNames from "classnames";
import './Panel.scss';
const Panel = (props: {className?: any, children: any, style?: any}) => {
    const {
        className,
        children,
        style
    } = props;
    return (
        <div className='ui-panel-wrapper'>
            <div  style={style} className={ classNames('ui-panel', className) }>
                {children}
            </div>
        </div>
    );
}

export default Panel;