import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBell} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {useTranslation} from "react-i18next";
import {LabeledAvatar} from "../../_ui/Avatar";
import {profileLink} from "../../../utils/linkUtils";
import {ProfileInterface} from "../../../interfaces/ProfileInterface";
import ActivityReactionGiven from "./NotificationContent/ActivityReactionGiven";
import Moment from "react-moment";
import classNames from "classnames";
import ProfileMentioned from "./NotificationContent/ProfileMentioned";
import {connect} from "react-redux";
import {markNotificationViewed} from "../../../redux/actions/notificationActions";
import OnWallActivityPublished from "./NotificationContent/OnWallActivityPublished";

const Notifications = (props: {
    notifications: {uuid: string, type: string, time: string, data: any, status: string}[],
    signedInProfile: ProfileInterface,
    setNotifications: (notifications: {uuid: string, type: string, time: string, data: any, status: string}[]) => void,
    hideContentsHandler: () => void
    dispatchMarkNotificationViewedAction: any
}) => {
    const {
        notifications,
        signedInProfile,
        setNotifications,
        hideContentsHandler,
        dispatchMarkNotificationViewedAction
    } = props;

    const { t } = useTranslation(['navBar']);

    const markNotificationAsViewed = (uuid: string) => {
        dispatchMarkNotificationViewedAction(uuid, (response: any) => {}, (error: any) => console.log(error));

        const updatedNotifications = notifications.map((notification) => {
            if (notification.uuid === uuid) {
                return { ...notification, status: "viewed" };
            }
            return notification;
        });

        // Update the state with the new array of notifications
        setNotifications(updatedNotifications);
    }

    const prepareNotificationContent = (notification: {uuid: string, type: string, time: string, data: any, status: string}) => {
        switch (notification.type) {
            case "ActivityReactionGiven":
                return <ActivityReactionGiven
                    notification={notification.data}
                    signedInProfile={signedInProfile}
                    hideContentsHandler={hideContentsHandler}
                />;
            case "ProfileMentioned":
                return <ProfileMentioned
                    notification={notification.data}
                    signedInProfile={signedInProfile}
                    hideContentsHandler={hideContentsHandler}
                />;
            case "OnWallActivityPublished":
                return <OnWallActivityPublished
                    notification={notification.data}
                    signedInProfile={signedInProfile}
                    hideContentsHandler={hideContentsHandler}
                />;
            default:
                return <>{notification.type}</>;
        }
    }

    if (notifications.length === 0) {
        return <div className={"nav-contents-empty"}>
            <FontAwesomeIcon icon={faBell} />
            <span>{t("navBar:notifications.empty")}</span>
        </div>
    }

    return <>
        {notifications.map((notification: any) => <div
            className={classNames("notification", notification.status)}
            key={notification.uuid}
            onClick={() => markNotificationAsViewed(notification.uuid)}
        >
            <div className={"notification-header"}>
                <LabeledAvatar
                    alt={notification.data.profile.name}
                    size={"size-s"}
                    src={notification.data.profile.avatar?.url}
                    linkTo={profileLink(notification.data.profile.slug)}
                    onClick={hideContentsHandler}
                >
                    <Moment fromNow={ true } interval={30000} date={ notification.time } />
                </LabeledAvatar>
            </div>
            <div className={"notification-content"}>{prepareNotificationContent(notification)}</div>
        </div>)}
    </>
}

const mapDispatchToProps = (dispatch: any) => ({
    "dispatchMarkNotificationViewedAction":  (uuid: string, onSuccess: any, onError: any) => dispatch(markNotificationViewed(uuid, onSuccess, onError)),
});

export default connect(null, mapDispatchToProps)(Notifications);