import {Link, Outlet} from "react-router-dom";
import {Helmet} from "react-helmet-async";
import Columns from "../../components/SportzzyUI/Layout/Columns";
import {Column, Main} from "../../components/SportzzyUI/Layout";

const Settings = () => {
    return (
        <>
            <Helmet>
                <title>Settings | Sportzzy</title>
                <meta name="robots" content='noindex,nofollow' />
            </Helmet>

            <Columns>
                <Column>
                    <ul>
                        <li><Link to={'/settings/account/'}>Account</Link></li>
                        <li><Link to={'/settings/sessions/'}>Sessions</Link></li>
                        <li><Link to={'/settings/notifications/'}>Notifications</Link></li>
                    </ul>
                </Column>
                <Main>
                    <Outlet />
                </Main>
            </Columns>
        </>
    );
}

export default Settings;