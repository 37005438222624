import Panel from "../../components/SportzzyUI/Panel/Panel";
import {Helmet} from "react-helmet-async";

const Notifications = () => {
    return  <>
        <Helmet>
            <title>Settings - Sessions | Sportzzy</title>
            <meta name="robots" content='noindex,nofollow' />
        </Helmet>

        <Panel>
            <h2>Notifications</h2>
        </Panel>
    </>;
};

export default Notifications;