import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {useTranslation} from "react-i18next";

const Messages = (props: {
    messages: any[]
}) => {
    const {
        messages
    } = props;
    const { t } = useTranslation(['navBar']);

    if (messages.length === 0) {
        return <div className={"nav-contents-empty"}>
            <FontAwesomeIcon icon={faEnvelope} />
            <span>{t("navBar:messages.empty")}</span>
        </div>
    }

    return <></>;
}

export default Messages;