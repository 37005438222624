import {profileActivityLink} from "../../../../utils/linkUtils";
import {Link} from "react-router-dom";
import React from "react";
import {ProfileInterface} from "../../../../interfaces/ProfileInterface";
import {useTranslation} from "react-i18next";

const ActivityReactionGiven = (props: {
    notification: {activity: {uuid: string}, reaction: string},
    signedInProfile: ProfileInterface,
    hideContentsHandler: () => void
}) => {
    const {
        notification,
        signedInProfile,
        hideContentsHandler
    } = props
    const { t } = useTranslation(['navBar']);

    return <Link
        onClick={hideContentsHandler}
        to={profileActivityLink(
            signedInProfile?.slug ?? "",
            notification.activity.uuid
        )}
    >
        {t(
            "navBar:notifications.activityReactionGiven",
            {"reaction": notification.reaction}
        )}
    </Link>
}

export default ActivityReactionGiven;