import {ProfileInterface} from "../../../interfaces/ProfileInterface";
import {useTranslation} from "react-i18next";
import classNames from "classnames";

const NoProfileContent = (props: {profile: ProfileInterface}) => {
    const { t, i18n } = useTranslation(['activity']);

    return <div className={classNames("no-content", "beautifier-white-bottom-right")}>
        {t("activity:feed.noContent.profile", {name: props.profile.name})}
    </div>
}

export default NoProfileContent;