import React from 'react';
import ReactDOM from 'react-dom/client';
import './components/_ui/index.scss';

import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom'
import {Provider} from "react-redux";
import {HelmetProvider} from "react-helmet-async";
import App from "./components/App/App";
import {configureStore} from "@reduxjs/toolkit";
import {apiMiddleware} from "./redux/middlewares";
import rootReducer from "./redux/reducers";

import './utils/i18n';
import './utils/moment';
import i18n from "i18next";

const root = ReactDOM.createRoot(
  document.getElementById('sportzzy') as HTMLElement
);

const store =  configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware => [
        ...getDefaultMiddleware({
            serializableCheck: {
                ignoredActionPaths: ['payload.success', 'payload.postProcessSuccess', 'payload.postProcessError'],
            },
        }),
        apiMiddleware
    ],
    devTools: process.env.NODE_ENV !== 'production',
});

document.documentElement.lang = i18n.language;

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <HelmetProvider context={{}}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </HelmetProvider>
        </Provider>
    </React.StrictMode>
);

reportWebVitals();
