import Panel from "../Panel/Panel";
import './Modal.scss';
import useKeypress from "../../../hooks/useKeyPressed";

const Modal = (props: {closeHandler: any, title?: string, children: any}) => {
    const {
        closeHandler,
        title,
        children
    } = props;

    useKeypress('Escape', () => {
        closeHandler();
    });

    return <div className='ui-modal'>
        <Panel>
            <div className='modal-header'>
                {title && <h2 className='modal-title'>{title}</h2>}
                <span className='close' onClick={closeHandler}>✖</span>
            </div>
            {children}
        </Panel>
    </div>
}

export default Modal;