import {ProfileInterface} from "../../../interfaces/ProfileInterface";
import {useTranslation} from "react-i18next";
import classNames from "classnames";
import ActivityHeader from "./Item/ActivityHeader";
import moment from "../../../utils/moment";
import ActivityContent from "./Item/ActivityContent";
import {useEffect, useRef, useState} from "react";
import {fetchProfileBySlug} from "../../../redux/actions/profileActions";
import {connect} from "react-redux";
import ActivityFeedItemLoader from "./ActivityFeedItemLoader";

const NoContent = (props: {
    signedInProfile: ProfileInterface,
    dispatchFetchSportzzyProfileAction: (onSuccess: any, onError: any) => {}
}) => {
    const {
        signedInProfile,
        dispatchFetchSportzzyProfileAction
    } = props;

    // const [sportzzyProfile, setSportzzyProfile] = useState<ProfileInterface>();
    const sportzzyProfile = {
        "uuid": "85ed2872-e328-4c74-b2fe-e216c0f214ab",
        "name": "Sportzzy Official",
        "type": "commercial",
        "slug": "sportzzy",
        "status": "published",
        "city": null
    };

    // const sportzzyRef = useRef<ProfileInterface>();
    const { t, i18n } = useTranslation(['activity']);

    // useEffect(() => {
    //     let isSubscribed = true;
    //
    //     if (sportzzyProfile !== undefined && sportzzyRef.current === sportzzyProfile) {
    //         return;
    //     }
    //
    //     dispatchFetchSportzzyProfileAction(
    //         (response: any) => isSubscribed ? setSportzzyProfile(response) : null,
    //         (error: any) => console.log(error)
    //     );
    //
    //     return () => { isSubscribed = false };
    // }, [sportzzyProfile]);

    if (!sportzzyProfile) {
        return <>
            <ActivityFeedItemLoader />
            <ActivityFeedItemLoader />
        </>
    }

    return <div className={classNames("activity-feed-item")}>
        <ActivityHeader
            hideTools={true}
            author={sportzzyProfile}
            occurredAt={moment().toISOString()}
            signedInProfile={signedInProfile}
            activityDeletedHandler={() => {}}
            beforeActivityDeletedHandler={() => {}}
            setToEditModeHandler={() => {}}
        />
        <ActivityContent content={{
            text: t(
                "activity:feed:noContent:general",
                {
                    name: signedInProfile.type === "commercial"
                        ? signedInProfile.name
                        : signedInProfile.name.split(' ')[0]
                }
            )
        }} />
    </div>
}

const mapDispatchToProps = (dispatch: any) => ({
    'dispatchFetchSportzzyProfileAction': (onSuccess: any, onError: any) => dispatch(fetchProfileBySlug("sportzzy", onSuccess, onError))
});

export default connect(null, mapDispatchToProps)(NoContent);