import * as constants from './../constants';

export const signUp = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'POST',
        url: '/auth/sign-up/',
        data,
        // success: (response) => ({ type: constants.SET_AUTH_TOKEN, payload: response }),
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});
export const signIn = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'POST',
        url: '/auth/sign-in/',
        data,
        success: (response) => ({ type: constants.SET_AUTH_TOKEN, payload: response }),
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const signOutCurrent = (onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'DELETE',
        url: '/auth/',
        success: (response) => ({ type: constants.CLEAR_AUTH }),
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const fetchAuthProfiles = (onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: '/auth/',
        success: (response) => ({ type: constants.FETCH_USER_PROFILES, payload: response.profiles }),
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const fetchActiveTokens = (onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: '/auth/tokens/',
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const deleteToken = (uuid, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'DELETE',
        url: '/auth/tokens/' + uuid + '/',
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});

export const changePassword = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'POST',
        url: '/auth/change-password/',
        data,
        success: (response) => ({ type: constants.CHANGE_PASSWORD, payload: response }),
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});