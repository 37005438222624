import * as constants from "../constants";

export const fetchPlacesBySport = (params: {sportId: number}, onSuccess: any, onError: any) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: '/places/',
        params: params,
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});