import moment from "moment";
import 'moment/locale/pl';
import 'moment/locale/en-gb';
import 'moment/locale/sv';
import 'moment/locale/de';
import 'moment/locale/es';

import i18n from "i18next";

moment.locale(i18n.language);

export default moment;