import * as constants from './../constants';

export const fetchProfilesByPhrase = (
    phrase: string,
    onSuccess: (response: any) => {},
    onError: (error: any) => {},
    cancelToken?: any
) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: '/profiles/',
        params: {search: {name: phrase, relation: "any"}},
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        cancelToken: cancelToken
    }
});

export const fetchProfileBySlug = (
    slug: string,
    onSuccess: (response: any) => {},
    onError: (error: any) => {}
) => ({
    type: constants.API,
    payload: {
        method: 'GET',
        url: '/profiles/',
        params: {slug: slug},
        postProcessSuccess: onSuccess,
        postProcessError: onError
    }
});